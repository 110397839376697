import React, { useEffect, useState } from "react";
import CustomHeader from "../../common/CustomHeader";
import { giftBanner, noData, walletIcon } from "../../../assets/media";
import GradientButton from "../GradientButton";
import {
  getRedeemHistory,
  redeemGiftCode,
} from "../../../utils/UserController";
import Toast from "../Toast";
import { formatDate, formatMoney } from "../../../utils/Utils";

const RedeemGift = () => {
  const [code, setCode] = useState("");
  const [history, setHistory] = useState([]);

  const [toast, setToast] = useState({
    isVisible: false,
    message: "",
  });

  const getRedeemHistoryData = async () => {
    try {
      const res = await getRedeemHistory();
      if (res.data.status) {
        setHistory(res.data.datas);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const showToast = (message) => {
    setToast({ isVisible: true, message });
    setTimeout(() => {
      setToast({ isVisible: false, message: "" });
    }, 3000); // Hide after 3 seconds
  };

  const redeemGiftCodeData = async () => {
    try {
      if(!code){
          showToast(`<svg xmlns="http://www.w3.org/2000/svg" width="35px" height="35px" viewBox="0 0 24 24"><path fill="#fff" d="M13 2v14.5h-2V2zm-2 17h2.004v2.004H11z"></path></svg>Please enter gift code`)
      }

      const res = await redeemGiftCode(code);
  
      if (res.data.status) {
        showToast(
          `<svg width="25px" height="25px" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="iconify iconify--emojione-monotone" preserveAspectRatio="xMidYMid meet" fill="#fff" stroke="#fff"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M56 2L18.8 42.909L8 34.729H2L18.8 62L62 2z" fill="#fff"></path></g></svg>Successfully redeemed`
        );
        getRedeemHistoryData();
      } else {
        showToast(
          `<svg xmlns="http://www.w3.org/2000/svg" width="35px" height="35px" viewBox="0 0 24 24"><path fill="#fff" d="M13 2v14.5h-2V2zm-2 17h2.004v2.004H11z"></path></svg>${res.data.message}`
        );
      }
    } catch (error) {}
  };

  useEffect(() => {
    getRedeemHistoryData();
  }, []);

  return (
    <>
      <CustomHeader
        path={"/activity"}
        bg={"var(--themeBlack)"}
        color={"var(--mainText)"}
        heading={"Gift"}
      />
      <div
        className="hero-header"
        style={{
          backgroundImage: `url(${giftBanner}), linear-gradient(94deg, #f99937 2.72%, #ff6922 43.54%, #ff8039 98.54%)`,
          height: "200px",
          backgroundSize: "100% 100%",
        }}
      ></div>
      <div className="receive-form">
        <div className="text">
          <span>Hi</span>
          <span>We have a gift for you</span>
        </div>
        <div className="form-container">
          <span style={{ color: "var(--lightText)" }}>
            Please enter the gift code below
          </span>
          <input
            type="text"
            placeholder="Please enter gift code"
            autoComplete="on"
            value={code}
            onChange={(e) => setCode(e.target.value)}
          />
          <div className="receive-btn" onClick={redeemGiftCodeData}>
            <GradientButton
              bg={"var(--main_gradient-color)"}
              text={"Receive"}
              color={"#fff"}
            />
          </div>
        </div>
      </div>
      <div className="gift-history-container">
        <div className="gift-history-header">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20px"
            height="20px"
            viewBox="0 0 24 24"
          >
            <path
              fill="#6485ff"
              d="M21 11.11V5a2 2 0 0 0-2-2h-4.18C14.4 1.84 13.3 1 12 1s-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v14a2 2 0 0 0 2 2h6.11c1.26 1.24 2.98 2 4.89 2c3.87 0 7-3.13 7-7c0-1.91-.76-3.63-2-4.89M12 3c.55 0 1 .45 1 1s-.45 1-1 1s-1-.45-1-1s.45-1 1-1M6 7h12v2H6zm3.08 10H6v-2h3.08c-.05.33-.08.66-.08 1s.03.67.08 1M6 13v-2h5.11c-.61.57-1.07 1.25-1.43 2zm10 8c-2.76 0-5-2.24-5-5s2.24-5 5-5s5 2.24 5 5s-2.24 5-5 5m.5-4.75l2.86 1.69l-.75 1.22L15 17v-5h1.5z"
            />
          </svg>
          <span>History</span>
        </div>
        <div className="gift-history-content">
          {history&& history?.length > 0 ? (
            history?.map((v, i) => (
              <div className="gift-history-item" key={i}>
                <div className="d-flex flex-col" style={{ gap: "5px" }}>
                  <span className="gift-sts">Successfully Received</span>
                  <span className="gft-dt">
                    {v.time}
                  </span>
                </div>
                <div className="gift-money">
                  <img src={walletIcon} alt="" />
                  <span>{formatMoney(v.money)}</span>
                </div>
              </div>
            ))
          ) : (
            <div className="empty">
              <img src={noData} alt="" className="noData" />
              <span>No Data</span>
            </div>
          )}
        </div>
      </div>
      <div className="size-box-20"></div>
      <Toast
        message={toast.message}
        isVisible={toast.isVisible}
        onClose={() => setToast({ isVisible: false, message: "" })}
      />
    </>
  );
};

export default RedeemGift;
