import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import BottomNav from "../common/BottomNav";
import CustomHeader from "../common/CustomHeader";
import "../../assets/css/promotion.css";
import GradientButton from "../components/GradientButton";
import Toast from "../components/Toast";
import { formatMoney, showLoader } from "../../utils/Utils";
import { getPromotion, getTotalCommission, getUser } from "../../utils/UserController";

const Promotion = () => {
  const [toast, setToast] = useState({
    isVisible: false,
    message: "",
  });

  const [user, setUser] = useState({});
  const [promotion, setPromotion] = useState([]);
  const [commission, setCommission] = useState([]);
  const navigate = useNavigate();

  const showToast = (message) => {
    setToast({ isVisible: true, message });
    setTimeout(() => {
      setToast({ isVisible: false, message: "" });
    }, 3000); // Hide after 3 seconds
  };
  const handleInvite = () => {
    navigate("/promotion/share", {
      state: user?.join_code,
    });
  };

  const handleCopy = (txt) => {
    navigator.clipboard
      .writeText(txt)
      .then(() => {
        showToast(
          `<svg width="25px" height="25px" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="iconify iconify--emojione-monotone" preserveAspectRatio="xMidYMid meet" fill="#fff" stroke="#fff"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M56 2L18.8 42.909L8 34.729H2L18.8 62L62 2z" fill="#fff"></path></g></svg>Copy successful`
        );
      })
      .catch((err) => {
        showToast("Failed to copy");
      });
  };
  useEffect(() => {
    const getUserData = async () => {
      showLoader(true);
      try {
        const res = await getUser();
        setUser(res.data.data);
        showLoader(false);
      } catch (error) {
        console.log(error);
      }
    };
    const getPromorationData = async () => {
      showLoader(true);
      try {
        const res = await getPromotion();
        setPromotion(res?.data);
        showLoader(false);
      } catch (error) {
        console.log(error);
      }
    };
    const getTotalCommissionData = async () => {
      showLoader(true);
      try {
        const res = await getTotalCommission();
        setCommission(res?.data);
        showLoader(false);
      } catch (error) {
        console.log(error);
      }
    };
    getUserData();
    getTotalCommissionData()
    getPromorationData();
  }, []);


  return (
    <>
      <CustomHeader
        bg={"var(-themeBlack)"}
        color={"var(--mainText)"}
        heading={"Agency"}
        tops="tops"
        
      />
      <div className="promotion-container">
        <span className="ytc-amt">{commission?.yesterdayBalance?commission?.yesterdayBalance?.toFixed(2):0
        }</span>
        <span className="ytc-text">Yesterday's total commission</span>
        <span className="tip">
          Upgrade the level to increase commission income
        </span>
        <div className="promo-table-container">
          <div className="table">
            <div className="table-header">
              <div className="table-row">
                <div className="table-col">Direct subordinates</div>
                <div className="border-s"></div>
                <div className="table-col">Team subordinates</div>
              </div>
            </div>
            <div className="table-body">
              <div className="table-row">
                <div className="table-col">
                  <div className="d-flex flex-col">
                    <span className="sub-num"> {promotion?.level1_count?promotion?.level1_count:'0'}</span>
                   
                    <span className="sub-txt">number of register</span>
                  </div>
                </div>
                <div className="table-col">
                  <div className="d-flex flex-col">
                    <span className="sub-num">{promotion?.level2_to_level6count?promotion?.level2_to_level6count:'0'}</span>
                    <span className="sub-txt">number of register</span>
                  </div>
                </div>
              </div>
              <div className="table-row">
                <div className="table-col">
                  <div className="d-flex flex-col">
                    <span className="sub-num text-green">
                    {promotion?.totalDepositCount?promotion?.totalDepositCount:"0"}
                    </span>
                    <span className="sub-txt">Deposit number</span>
                  </div>
                </div>
                <div className="table-col">
                  <div className="d-flex flex-col">
                    <span className="sub-num text-green">{promotion?.level2_to_level6totalDepositCount?promotion?.level2_to_level6totalDepositCount:'0'}</span>
                    <span className="sub-txt">Deposit number</span>
                  </div>
                </div>
              </div>
              <div className="table-row">
                <div className="table-col">
                  <div className="d-flex flex-col">
                    <span className="sub-num text-yellow">
                    {promotion?.totalDepositAmount?promotion?.totalDepositAmount:"0"}
                    </span>
                    <span className="sub-txt">Deposit amount</span>
                  </div>
                </div>
                <div className="table-col">
                  <div className="d-flex flex-col">
                    <span className="sub-num text-yellow">{promotion?.level2_to_level6totalDepositAmount?promotion?.level2_to_level6totalDepositAmount:'0'}</span>
                    <span className="sub-txt">Deposit amount</span>
                  </div>
                </div>
              </div>
              <div className="table-row">
                <div className="table-col">
                  <div className="d-flex flex-col">
                    <span className="sub-num">{promotion?.firstDepositCount?promotion?.firstDepositCount:'0'}</span>
                    <span className="sub-txt">
                      {" "}
                      Number of people making first deposit
                    </span>
                  </div>
                </div>
                <div className="table-col">
                  <div className="d-flex flex-col">
                    <span className="sub-num">{promotion?.level2_to_level6firstDepositCount?promotion?.level2_to_level6firstDepositCount:'0'}</span>
                    <span className="sub-txt">
                      {" "}
                      Number of people making first deposit
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="invite-link-btn" onClick={handleInvite}>
          <GradientButton
            text={"INVITATION LINK"}
            width={"90%"}
            textsize="1rem"
            fw={"bold"}
            bg={"var(--main_gradient-color)"}
            color={"#fff"}
          />
        </div>
        <div className="sub-link-container">
          <div className="sub-link">
            <div className="d-flex align-center" style={{ gap: "5px" }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30px"
                height="30px"
                viewBox="0 0 24 24"
              >
                <g fill="none">
                  <path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035q-.016-.005-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093q.019.005.029-.008l.004-.014l-.034-.614q-.005-.019-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" />
                  <path
                    fill="#6485ff"
                    d="M17 3a3 3 0 0 1 2.995 2.824L20 6v4.35l.594-.264c.614-.273 1.322.15 1.4.798L22 11v8a2 2 0 0 1-1.85 1.995L20 21H4a2 2 0 0 1-1.995-1.85L2 19v-8c0-.672.675-1.147 1.297-.955l.11.041l.593.264V6a3 3 0 0 1 2.824-2.995L7 3zm0 2H7a1 1 0 0 0-1 1v5.239l6 2.667l6-2.667V6a1 1 0 0 0-1-1m-5 3a1 1 0 0 1 .117 1.993L12 10h-2a1 1 0 0 1-.117-1.993L10 8z"
                  />
                </g>
              </svg>
              <span className="sub-link-txt">Copy invitation code</span>
            </div>
            <div
              className="d-flex align-center"
              style={{ gap: "5px", color: "#768096" }}
              onClick={() => handleCopy(user?.code)}
            >
              <span>{user?.code}</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20px"
                height="20px"
                viewBox="0 0 256 256"
              >
                <path
                  fill="#768096"
                  d="M216 32H88a8 8 0 0 0-8 8v40H40a8 8 0 0 0-8 8v128a8 8 0 0 0 8 8h128a8 8 0 0 0 8-8v-40h40a8 8 0 0 0 8-8V40a8 8 0 0 0-8-8m-56 176H48V96h112Zm48-48h-32V88a8 8 0 0 0-8-8H96V48h112Z"
                />
              </svg>
            </div>
          </div>
          <Link to={"/promotion/SubordinateData"}>
            <div className="sub-link">
              <div className="d-flex align-center" style={{ gap: "5px" }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30px"
                  height="30px"
                  viewBox="0 0 24 24"
                >
                  <g
                    fill="none"
                    stroke="#6485ff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                  >
                    <path d="M22 14V6a3 3 0 0 0-3-3H9a3 3 0 0 0-3 3v7" />
                    <path d="M12 21H6a4 4 0 0 1 0-8h12a4 4 0 1 0 4 4v-3" />
                  </g>
                </svg>
                <span className="sub-link-txt">Subordinate data</span>
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25px"
                height="25px"
                viewBox="0 0 24 24"
              >
                <path
                  fill="#1e2637"
                  d="m8.006 21.308l-1.064-1.064L15.187 12L6.942 3.756l1.064-1.064L17.314 12z"
                />
              </svg>
            </div>
          </Link>
          <Link to={"/promotion/CommissionDetails"}>
            <div className="sub-link">
              <div className="d-flex align-center" style={{ gap: "5px" }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30px"
                  height="30px"
                  viewBox="0 0 24 24"
                >
                  <g fill="none" fillRule="evenodd">
                    <path d="M0 0h24v24H0z" />
                    <path
                      fill="#6485ff"
                      d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12m11-5a1 1 0 1 0-2 0v1h-1a2.5 2.5 0 0 0 0 5h4a.5.5 0 0 1 0 1H9a1 1 0 1 0 0 2h2v1a1 1 0 1 0 2 0v-1h1a2.5 2.5 0 0 0 0-5h-4a.5.5 0 0 1 0-1h5a1 1 0 1 0 0-2h-2z"
                    />
                  </g>
                </svg>
                <span className="sub-link-txt">Commission detail</span>
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25px"
                height="25px"
                viewBox="0 0 24 24"
              >
                <path
                  fill="#1e2637"
                  d="m8.006 21.308l-1.064-1.064L15.187 12L6.942 3.756l1.064-1.064L17.314 12z"
                />
              </svg>
            </div>
          </Link>
          <Link to={"/promotion/PromotionRule"}>
            <div className="sub-link">
              <div className="d-flex align-center" style={{ gap: "5px" }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30px"
                  height="30px"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill="#6485ff"
                    d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0M9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1M3 9h10v1H6v2h7v1H6v2H5v-2H3v-1h2v-2H3z"
                  />
                </svg>
                <span className="sub-link-txt">Invitation rules</span>
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25px"
                height="25px"
                viewBox="0 0 24 24"
              >
                <path
                  fill="#1e2637"
                  d="m8.006 21.308l-1.064-1.064L15.187 12L6.942 3.756l1.064-1.064L17.314 12z"
                />
              </svg>
            </div>
          </Link>
          <Link to={"/promotion/CustomerSupport"}>
            <div className="sub-link">
              <div className="d-flex align-center" style={{ gap: "5px" }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30px"
                  height="30px"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="#6485ff"
                    d="M18.72 14.76c.35-.85.54-1.76.54-2.76c0-.72-.11-1.41-.3-2.05c-.65.15-1.33.23-2.04.23A9.07 9.07 0 0 1 9.5 6.34a9.2 9.2 0 0 1-4.73 4.88c-.04.25-.04.52-.04.78A7.27 7.27 0 0 0 12 19.27c1.05 0 2.06-.23 2.97-.64c.57 1.09.83 1.63.81 1.63c-1.64.55-2.91.82-3.78.82c-2.42 0-4.73-.95-6.43-2.66a9 9 0 0 1-2.24-3.69H2v-4.55h1.09a9.09 9.09 0 0 1 15.33-4.6a9 9 0 0 1 2.47 4.6H22v4.55h-.06L18.38 18l-5.3-.6v-1.67h4.83zm-9.45-2.99c.3 0 .59.12.8.34a1.136 1.136 0 0 1 0 1.6c-.21.21-.5.33-.8.33c-.63 0-1.14-.5-1.14-1.13s.51-1.14 1.14-1.14m5.45 0c.63 0 1.13.51 1.13 1.14s-.5 1.13-1.13 1.13s-1.14-.5-1.14-1.13a1.14 1.14 0 0 1 1.14-1.14"
                  />
                </svg>
                <span className="sub-link-txt">
                  Agent line customer service
                </span>
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25px"
                height="25px"
                viewBox="0 0 24 24"
              >
                <path
                  fill="#1e2637"
                  d="m8.006 21.308l-1.064-1.064L15.187 12L6.942 3.756l1.064-1.064L17.314 12z"
                />
              </svg>
            </div>
          </Link>
          <Link to={"/promotion/RebateRatio"}>
            <div className="sub-link">
              <div className="d-flex align-center" style={{ gap: "5px" }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30px"
                  height="30px"
                  viewBox="0 0 48 48"
                >
                  <g fill="none">
                    <path d="M0 0h48v48H0z" />
                    <path
                      fill="#6485ff"
                      fillRule="evenodd"
                      d="M24.039 6c-4.517 0-8.632 1.492-11.067 2.711q-.33.165-.616.322c-.378.206-.7.398-.956.567l2.77 4.078l1.304.519c5.096 2.571 11.93 2.571 17.027 0l1.48-.768L36.6 9.6a16 16 0 0 0-1.689-.957C32.488 7.437 28.471 6 24.04 6m-6.442 4.616a25 25 0 0 1-2.901-.728C16.978 8.875 20.377 7.8 24.04 7.8c2.537 0 4.936.516 6.92 1.17c-2.325.327-4.806.882-7.17 1.565c-1.86.538-4.034.48-6.192.081m15.96 5.064l-.246.124c-5.606 2.828-13.042 2.828-18.648 0l-.233-.118C6.008 24.927-.422 41.997 24.039 41.997S41.913 24.61 33.557 15.68M23 24a2 2 0 1 0 0 4zm2-2v-1h-2v1a4 4 0 0 0 0 8v4c-.87 0-1.611-.555-1.887-1.333a1 1 0 1 0-1.885.666A4 4 0 0 0 23 36v1h2v-1a4 4 0 0 0 0-8v-4c.87 0 1.611.555 1.887 1.333a1 1 0 1 0 1.885-.666A4 4 0 0 0 25 22m0 8v4a2 2 0 1 0 0-4"
                      clipRule="evenodd"
                    />
                  </g>
                </svg>
                <span className="sub-link-txt">Rebate ratio</span>
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25px"
                height="25px"
                viewBox="0 0 24 24"
              >
                <path
                  fill="#1e2637"
                  d="m8.006 21.308l-1.064-1.064L15.187 12L6.942 3.756l1.064-1.064L17.314 12z"
                />
              </svg>
            </div>
          </Link>
          <div
            className="gift-history-container"
            style={{ width: "100%", height: "auto" }}
          >
            <div className="gift-history-header">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20px"
                height="20px"
                viewBox="0 0 640 512"
              >
                <path
                  fill="#6485ff"
                  d="M96 96v224c0 35.3 28.7 64 64 64h416c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H160c-35.3 0-64 28.7-64 64m64 160c35.3 0 64 28.7 64 64h-64zm64-160c0 35.3-28.7 64-64 64V96zm352 160v64h-64c0-35.3 28.7-64 64-64M512 96h64v64c-35.3 0-64-28.7-64-64M288 208a80 80 0 1 1 160 0a80 80 0 1 1-160 0M48 120c0-13.3-10.7-24-24-24S0 106.7 0 120v240c0 66.3 53.7 120 120 120h400c13.3 0 24-10.7 24-24s-10.7-24-24-24H120c-39.8 0-72-32.2-72-72z"
                />
              </svg>
              <span>promotion data</span>
            </div>

            <div className="promotion-data-container">
              <div className="promotion-data">
                <span className="sub-num">{commission?.weekBalance?Number(commission?.weekBalance)?.toFixed(2):0}</span>
                <span className="sub-txt">This Week</span>
              </div>
              <div className="promotion-data">
                <span className="sub-num">
                  {commission?.totalBalance?Number(commission?.totalBalance)?.toFixed(2):0}
                </span>
                <span className="sub-txt">Total commission</span>
              </div>
              <div className="promotion-data">
                <span className="sub-num">{promotion?.invite?.f1?promotion?.invite?.f1:0}</span>
                <span className="sub-txt">direct subordinate</span>
              </div>
              <div className="promotion-data">
                <span className="sub-num">{promotion?.total_downline_count?promotion?.total_downline_count:0}</span>
                <span className="sub-txt">
                  Total number of subordinates in the team
                </span>
              </div>
            </div>
          </div>
          <div className="size-box-50"></div>
          <div className="size-box-50"></div>
        </div>
      </div>
      <BottomNav />
      <Toast
        message={toast.message}
        isVisible={toast.isVisible}
        onClose={() => setToast({ isVisible: false, message: "" })}
      />
    </>
  );
};

export default Promotion;
// https://pay.inrupay.in/#/?sn=SW11OMGO4m02Rgpd&amount=1&tradeSn=T24111523532xufTo&merchantTradeSn=1731695028409400