import React, { useEffect, useState } from "react";
import { formatMoney, showLoader } from "../../../utils/Utils";
import CustomHeader from "../../common/CustomHeader";
import { ruleHeadingBg } from "../../../assets/media";
import { Link } from "react-router-dom";
import { claimFD, getUser } from "../../../utils/UserController";
import Toast from "../Toast";

const FirstDeposit = () => {
  const [user, setUser] = useState({});
  const [trigger, setTrigger] = useState(false);
  const fDeposit = [
    {
      deposit: 100,
      bonus: 28,
    },
    {
      deposit: 200,
      bonus: 48,
    },
    {
      deposit: 500,
      bonus: 108,
    },
    {
      deposit: 1000,
      bonus: 188,
    },
    {
      deposit: 5000,
      bonus: 488,
    },
    {
      deposit: 24000,
      bonus: 1388,
    },
    {
      deposit: 120000,
      bonus: 5888,
    },
    {
      deposit: 240000,
      bonus: 8888,
    },
  ];
  const rules = [
    "Exclusive for the first recharge of the account. There is only one chance. The more you recharge, the more rewards you will receive. The highest reward is ₹8,888.00;",
    "During the activity, please check once a day",
    "Rewards can only be claimed manually on IOS, Android, H5, and PC;",
    "The bonus (excluding the principal) given in this event requires 1.00 times the coding turnover (i.e. valid bets) before it can be withdrawn, and the coding does not limit the platform;",
    "This event is limited to normal human operations by the account owner. It is prohibited to rent, use plug-ins, robots, gamble with different accounts, brush each other, arbitrage, interfaces, protocols, exploit loopholes, group control or other technical means to participate, otherwise it will be canceled or Rewards will be deducted, frozen, or even blacklisted;",
    "In order to avoid differences in text understanding, the platform reserves the right of final interpretation of this event.",
  ];
  const [toast, setToast] = useState({
    isVisible: false,
    message: "",
  });
  const showToast = (message) => {
    setToast({ isVisible: true, message });
    setTimeout(() => {
      setToast({ isVisible: false, message: "" });
    }, 3000);
  };

  const handleClick = async () => {
    try {
      const res = await claimFD();
      if (res.data.status) {
        showToast(
          `<svg xmlns="http://www.w3.org/2000/svg" width="35px" height="35px" viewBox="0 0 24 24"><path fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth={3} d="M5 14.5s1.5 0 3.5 3.5c0 0 5.559-9.167 10.5-11" color="#fff"></path></svg>${res.data.message}`
        );
        setTrigger(!trigger);
      } else {
        showToast(
          `<svg xmlns="http://www.w3.org/2000/svg" width="35px" height="35px" viewBox="0 0 24 24"><path fill="#fff" d="M13 2v14.5h-2V2zm-2 17h2.004v2.004H11z"></path></svg>${res.data.message}`
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const getUserData = async () => {
      try {
        const res = await getUser();
        setUser(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getUserData();
  }, [trigger]);

  return (
    <>
      <CustomHeader
        bg={"var(--themeBlack)"}
        color={"var(--mainText)"}
        heading={"First deposit bonus"}
        path={"/"}
      />
      <div className="first-deposit">
        {fDeposit.map((v, i) => (
          <div
            className="first_list-item"
            key={i}
            style={{ margin: "15px 10px" }}
          >
            <div className="fs-head">
              <div className="fs-title">
                <span>First deposit </span>
                <span style={{ color: "#ff9832" }}>{v.deposit}</span>
              </div>
              <span style={{ color: "#ff9832" }}>+{formatMoney(v.bonus)}</span>
            </div>
            <div className="fs-des">
              Deposit {v.deposit} for the first time and you will receive{" "}
              {v.bonus} bonus
            </div>
            <div className="d-flex align-center justify-between">
              <div className="fs-comp">
                <span>
                  {user?.first_deposit >= v.bonus
                    ? v.deposit
                    : Number(user?.totalDeposit)  === Number(v.deposit)
                    ? v.deposit
                    : 0}
                </span>
                /<span>{v.deposit}</span>
              </div>
              <div
                className={`fs-deposit-btn ${
                  Number(user?.first_deposit) === Number(v.bonus)
                    ? "active"
                    : Number(user?.totalDeposit)  === Number(v.deposit)
                    ? "active"
                    : ""
                }`}
                onClick={handleClick}
              >
                {Number(user?.totalDeposit) === Number(v.bonus)
                  ? "Claim"
                  : Number(user?.totalDeposit) === Number(v.deposit)
                  ? "Received"
                  : Number(user?.totalDeposit) === 0
                  ? "Deposit"
                  : "Missed"}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="p-rule-container">
        <div
          className="heading"
          style={{
            backgroundImage: `url('${ruleHeadingBg}')`,
            filter: "hue-rotate(230deg)",
          }}
        >
          Activity Rules
        </div>
        <div className="rule-container">
          {rules.map((v, i) => (
            <div className="list" key={i}>
              <span>{v}</span>
            </div>
          ))}
        </div>
      </div>
      <Toast
        message={toast.message}
        isVisible={toast.isVisible}
        onClose={() => setToast({ isVisible: false, message: "" })}
      />
    </>
  );
};

export default FirstDeposit;
