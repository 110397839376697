import React from "react";
import BottomNav from "../common/BottomNav";
import CustomHeader from "../common/CustomHeader";
import "../../assets/css/activity.css";
import {
  invitationBonus,
  bettingRebate,
  superJackpot,
  signInBanner,
  giftBanner,
  giftRedeem,
  banner7,
    banner1,
  banner2,
  banner3,
  banner4,
  banner5,
  banner6,
  banner8,
  banner9,
  banner10,
  banner11,
} from "../../assets/media";
import activityreward from "../../assets/img/activityreward.png"
import { Link, useNavigate } from "react-router-dom";

const Activity = () => {
  const navigate = useNavigate();
  
  const images = [
  banner6,
  banner2,
    banner1,
  banner3,
  banner4,
  banner5,
  banner8,
  banner9,
  banner10,
  banner11];

  const handleClick = (route) => {
    navigate(route);
  };

  return (
    <>
      <CustomHeader bg={"var(--main_gradient-color)"} logo={true} />
      <div className="info">
        <span className="info-heading">Activity</span>
        <span className="info-text" style={{ fontSize: ".85rem" }}>
          Please remember to follow the event page
        </span>
        <span className="info-text" style={{ fontSize: ".85rem" }}>
          We will launch user feedback activities from time to time
        </span>
      </div>
      <div className="activity-tabs">
        <div className="activity-tab">
          <Link to={"/activity/FirstDeposit"}>
            <img src={activityreward} alt="" />
          </Link>
          <span>Activity Reward</span>
        </div>
        <div className="activity-tab">
          <Link to={"/activity/InvitationBonus"}>
            <img src={invitationBonus} alt="" />
          </Link>
          <span>Invitation bonus</span>
        </div>
        <div className="activity-tab">
          <Link to={"/activity/BettingRebet"}>
            <img src={bettingRebate} alt="" />
          </Link>
          <span>Betting rebate</span>
        </div>
        <div className="activity-tab">
          <Link to={"/activity/SuperJackpot"}>
            <img src={superJackpot} alt="" />
          </Link>
          <span>Super jackpot</span>
        </div>
      </div>
      <div className="card-tabs">
        <div
          className="card"
          onClick={() => handleClick("/activity/RedeemGift")}
        >
          <img src={signInBanner} alt="" />
          <div className="card-body">
            <div className="card-title">Gift</div>
            <div className="card-des">
              Enter the redemption code to receive gift rewards
            </div>
          </div>
        </div>
        <div
          className="card"
          onClick={() => handleClick("/activity/Attendance")}
        >
          <img src={giftRedeem} alt="" />
          <div className="card-body">
            <div className="card-title">Attendance bonus</div>
            <div className="card-des">
              The more consecutive days you sign in, the higher the reward will
              be.
            </div>
          </div>
        </div>
      </div>
      <div className="card-banners">
        {images.map((a, i) => (
          <Link to={i === 1 ? "/activity/FirstDeposit" : "/activity"} key={i}>
            <div className="card-banner">
              <img src={a} alt="" />
            </div>
          </Link>
        ))}
        <div className="no-more">No more</div>
      </div>
      <BottomNav />
    </>
  );
};

export default Activity;
