import React, { useState, useEffect, useRef } from "react";
import $, { isEmptyObject, type } from "jquery";
import socket, { webhost } from "../../../../socket";
import { checkNumbers, formatDate , formatMoney} from "../../../../utils/Utils";
import { PiCopySimpleBold } from "react-icons/pi";
import {
  lowAudio,
  highAudio,
  notFoundImg,
  checkImg,
  checkCircle,
  ludo1,
  ludo2,
  ludo3,
  ludo4,
  ludo5,
  ludo6,
  xludo1,
  xludo2,
  xludo3,
  xludo4,
  xludo5,
  xludo6,
  ballNum1,
  redBallBg,
  greenBallBg,
  noData,
} from "../../../../assets/media";
import {
  getUser,
  getUserBetHistory,
  getK3BetHistory,
  getUserWinStatus,
} from "../../../../utils/UserController";
import { getK3List } from "../../../../utils/DataController";
import { betK3 } from "../../../../utils/BetController";
import "../../../../assets/css/k3.css";
import "../../../../assets/css/wingo.css";
import { io } from "socket.io-client";
import Toast2 from "../../Toast2";
import Toast from "../../Toast";

const K35 = ({ xmoney, user_id, updateUser, showResult }) => {
  const [money, setMoney] = useState(0);
  const [activeTab, setActiveTab] = useState("Game History");
  const [betActiveTab, setBetActiveTab] = useState("Total");
  const [bg, setBg] = useState("");
  const [agree, setAgree] = useState(true);
  const [betType, setBetType] = useState("total");
  const [isOpen, setIsOpen] = useState(false);
  const [mltValue, setMltValue] = useState(1);
  const [betAmount, setBetAmount] = useState(1);
  const [balance, setBalance] = useState(1);
  const [wingoList, setWingoList] = useState([]);
  const [nextPreiod, setNextPreiod] = useState(20240000000);
  const [triggerEffect, setTriggerEffect] = useState(false);
  const [triggerEffectTotal, setTriggerEffectTotal] = useState(false);
  const [triggerEffectTwoSame, setTriggerEffectTwoSame] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [timer, setTimer] = useState(0);
  const [qty2, setQty2] = useState(1);
  const [bet, setBet] = useState(null);
  const [selectTab, setSelectTab] = useState(null);
  const [selectBet, setSelectBet] = useState(null);
  const [minutetime2, setMinutetime2] = useState(0);
  const [secondtime1, setSecondtime1] = useState(0);
  const [secondtime2, setSecondtime2] = useState(0);
  const [numbers, setNumbers] = useState([6, 6, 6]);

  const low_audio = new Audio(lowAudio);
  const high_audio = new Audio(highAudio);
  let removeModel;

  const fetchWingoList = async (page, pageto) => {
    try {
      const response = await getK3List(5, page, pageto);
      if (response.data.status) {
        setWingoList(response?.data?.data?.gameslist);
        if (page === 1) {

          const numbers = response.data.data.gameslist[0].result;
          setNextPreiod(response?.data?.period);

          const numberArray = Array.from(String(numbers), Number); // Convert to array [4, 9, 3]
          setNumbers(numberArray);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchUserWinStatus = async () => {
    try {
      const response = await getUserWinStatus("k3", "5min");
      const status = response.data.data.win_status;
      const betData = response.data.data.bet_data;
      const data = {
        status: status,
        type: "1",
        period_id: response.data.data.period_id,
        amount: response.data.data.amount,
        win_amount: response.data.data.result_amount,
        color: betData.ludo_result,
        size: betData.size_result,
        number: betData.num_result,
      };
      showResult(data);
    } catch (err) {
      console.log(err);
    }
  };

  const refreshUserData = async () => {
    const userData = await getUser();
    if (userData.status === 200) {
      updateUser(userData);
      setMoney(userData.data.data.money);
    }
  };

  const getRandomNumber = () => Math.floor(Math.random() * 6) + 1;

  const randomize = () => {
    const interval = setInterval(() => {
      setNumbers([getRandomNumber(), getRandomNumber(), getRandomNumber()]);
    }, 100);

    const timeout = setTimeout(() => {
      clearInterval(interval);
      const fetchData = async () => {
        const response = await getK3List(10, 1,10);
        if (response.data.status) {
          const numbers = response.data.data.gameslist[0].result.split(",");
          setNumbers(numbers);
        }
      };
      fetchData();
    }, 3000);

    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
  };

  const toggleOffCanvas = (open, data, type) => {
    setBetType(type);
    setIsOpen(open);
    if (type === "total") {
      if (
        data.selectedBalls.length > 0 ||
        data.selectedBigSmall !== null ||
        data.selectedOddEven !== null
      ) {
        data.type = "total";
        const qty2 =
          data.selectedBalls.length +
          (data.selectedBigSmall !== null ? 1 : 0) +
          (data.selectedOddEven !== null ? 1 : 0);
        setQty2(qty2);
        setSelectTab(1)
        setBet(data);
        // Map the values as specified
        const oddEvenMapping = {
          Odd: "l",
          Even: "c"
        };

        const bigSmallMapping = {
          Big: "b",
          Small: "s"
        };


        // Create the result string
        const result = [
          ...data.selectedBalls,
          oddEvenMapping[data.selectedOddEven] || null,
          bigSmallMapping[data.selectedBigSmall] || null
        ].filter(Boolean).join(",");

        setSelectBet(result)
      }



    } else if (type === "two_same") {
      if (
        data.selectedMatches.length > 0 ||
        data.selectedPairRed.length > 0 ||
        data.selectedPairGreen.length > 0
      ) {
        data.type = "two_same";

        const qty2 =
          data.selectedMatches.length +
          data.selectedPairRed.length * data.selectedPairGreen.length;
        setQty2(qty2);
        setBet(data);

        const result = [
          ...data.selectedMatches,
          ...data.selectedPairGreen,
          ...data.selectedPairRed
        ].filter(Boolean).join(",");

        setSelectBet(result)
        setSelectTab(2)

      }
    } else if (type === "three_same") {
      if (data.selectedMatches.length > 0 || data.threeOdd) {
        data.type = "three_same";
        const qty2 = data.selectedMatches.length + (data.threeOdd ? 1 : 0);
        setQty2(qty2);
        setBet(data);
        const result = [
          ...data.selectedMatches,
          data.threeOdd ? "@3" : null
        ].filter(Boolean).join(",");
        setSelectBet(result)
        setSelectTab(3)
      }
    } else if (type === "different") {
      if (
        data.threeDiffOdd.length === 3 ||
        data.twoDiffOdd.length === 2 ||
        data.threeContinous
      ) {
        data.type = "different";
        const qty2 =
          (data.threeDiffOdd.length === 3 ? 1 : 0) +
          (data.twoDiffOdd.length === 2 ? 1 : 0) +
          (data.threeContinous ? 1 : 0);
        setQty2(qty2);
        setBet(data);

        const result = [
          ...data.threeDiffOdd,
          data.threeContinous ? "@u@" : null,
          ...data.twoDiffOdd
        ].filter(Boolean).join(",");
        setSelectBet(result)
        setSelectTab(4)
      }
    }
    if (!open) {
      refreshUserData();
    } else {
      $(".k3-model-content1").prop("class", "k3-model-content1");
    }
  };


  const tabs = [
    {
      label: "Game History",
      content: <GameHistory triggerEffect={triggerEffect} />,
    },
    {
      label: "Chart",
      content: <Chart triggerEffect={triggerEffect} />,
    },
    {
      label: "My History",
      content: <MyHistory triggerEffect={triggerEffect} />,
    },
  ];

  const handleTabClick = (label) => {
    setActiveTab(label);
  };

  const handleBetTabClick = (label) => {
    setBet(null);
    setBetType(label);
    setBetActiveTab(label);
  };

  const handleInput = (e) => {
    let value = e.target.value;
    if (
      value !== "" &&
      !isNaN(value) &&
      Number(value) >= 1 &&
      Number(value) <= 9999
    ) {
       setMltValue(Number(Math.floor(value)));
    }
  };

  const handleMlt = (type) => {
    setMltValue((prevMltValue) => {
      let newValue = type ? prevMltValue + 1 : prevMltValue - 1;
      if (newValue < 1) newValue = 1;
      if (newValue > 9999) newValue = 9999;
      return newValue;
    });
  };

  const handleMlt2 = (value) => {
    setMltValue((prevMltValue) => {
      let newValue = value;
      return newValue;
    });
  };

  const handleBetAmount = (balance, mltValue, qty2) => {
    const totalBet = balance * mltValue * qty2;
    setBetAmount(totalBet);
  };

  const handleBalance = (value) => {
    setBalance(value);
  };
const [toast, setToast] = useState({
    isVisible: false,
    message: "",
  });
  const showToast = (message) => {
    setToast({ isVisible: true, message });
    setTimeout(() => {
      setToast({ isVisible: false, message: "" });
    }, 3000); // Hide after 3 seconds
  };
  const confirmBet = async () => {
    clearTimeout(removeModel);
    if (!agree && true) {
      $("#smallPopup").addClass("show");
      $("#smallPopup").text("Please check I agree to the pre-sale rules");
      removeModel = setTimeout(
        () => {
          $("#smallPopup").removeClass("show");
        },
        2000,
        () => {
          $("#smallPopup").text("");
        }
      );
      return;
    }

    if (xmoney < betAmount) {
      $("#smallPopup").addClass("show");
      $("#smallPopup")
        .html(`<span style="font-size:40px;">!</span><span>Error: 142 </br>
 Insufficient balance</span>`);
      removeModel = setTimeout(
        () => {
          $("#smallPopup").removeClass("show");
        },
        2000,
        () => {
          $("#smallPopup").text("");
        }
      );
      return;
    }

  
    const response = await betK3(
      5,
      selectBet,
      selectTab,
      betAmount,
    );

    if (response.status) {
      refreshUserData();
      setTriggerEffectTotal((prev) => !prev);
      setTriggerEffectTwoSame((prev) => !prev);
      setTriggerEffect((prevState) => !prevState);
      // $("#smallPopup").addClass("show");
      // $("#smallPopup").text(response.data.message);
      showToast("Bet succeed");
      toggleOffCanvas(false, null, null);
      setSelectTab(null)
      setSelectBet(null)
      removeModel = setTimeout(
        () => {
          $("#smallPopup").removeClass("show");
        },
        2000,
        () => {
          $("#smallPopup").text("");
        }
      );
    }
  };

  const handleAgree = () => {
    setAgree(!agree);
  };

  
  const soundEnabled = localStorage.getItem("soundEnabled") === "true";
  useEffect(() => {
    socket.current = io.connect(webhost);
    const eventName = `timeUpdate_5`;
    socket.current.on(eventName, (data) => {
      if (!data) {
        return "not event";
      }

      const { minute, secondtime1, secondtime2 } = data;
      setMinutetime2(minute);
      setSecondtime1(secondtime1);
      setSecondtime2(secondtime2);

      // Handle open time logic based on received data
      if (minute === 0 && secondtime1 === 0 && secondtime2 <= 5 && secondtime2 >= 1) {
        if (soundEnabled) low_audio.play();;
        $(".bet-section-model").addClass("show");
        $(".bet-section-model").removeClass("hide");
        setIsOpen(false);
        $(".overlay").addClass("d-none");
        $(".container").css("overflow", "");
      } else {
        $(".bet-section-model").addClass("hide");
        $(".bet-section-model").removeClass("show");
      }

      if (minute === 4 && secondtime1 === 5 && secondtime2 === 9) {
        if (soundEnabled) high_audio.play();

      }
    });
    return () => {
      socket.current.off(eventName);
      socket.current.disconnect(); // Disconnect socket when unmounting
    };
  }, [soundEnabled])


  const ludos = [xludo1, xludo2, xludo3, xludo4, xludo5, xludo6];

  const bet_tabs = [
    {
      label: "Total",
      content: (
        <TotalBet
          betOpen={toggleOffCanvas}
          triggerEffect={triggerEffectTotal}
        />
      ),
    },
    {
      label: "2 Same",
      content: (
        <TwoSame
          betOpen={toggleOffCanvas}
          triggerEffect={triggerEffectTwoSame}
        />
      ),
    },
    {
      label: "3 Same",
      content: (
        <ThreeSame
          betOpen={toggleOffCanvas}
          triggerEffect={triggerEffectTwoSame}
        />
      ),
    },
    {
      label: "Different",
      content: (
        <Different
          betOpen={toggleOffCanvas}
          triggerEffect={triggerEffectTwoSame}
        />
      ),
    },
  ];

  useEffect(() => {
    setMoney(xmoney);
    fetchWingoList(1,10);
  }, [triggerEffect]);

  useEffect(() => {
    handleBetAmount(balance, mltValue, qty2);
  }, [balance, mltValue, qty2]);

  useEffect(() => {
    socket.on("data-server-k3", async (data) => {

      const response = await getK3BetHistory(1, 10, 5);


      // fetchUserWinStatus();
      fetchWingoList(1, 10);

      
      
      if (data?.data[1]?.period ==response?.data?.data?.gameslist[0]?.stage) {
        const status = response.data.data.gameslist[0].status;
        const datas = {
          status: status,
          type: "5Min",
          period_id: response.data.data.gameslist[0].stage,
          amount: response.data.data.gameslist[0].money,
          win_amount: response.data.data.gameslist[0].get,
          game: "K3",
          color: response.data.data.gameslist[0].bet,
          size: response.data.data.gameslist[0].bet,
          number: response.data.data.gameslist[0].result,
        };
        showResult(datas);
        setIsOpen(false);
        $(".overlay").addClass("d-none");
        $(".container").css("overflow", "");
        setTriggerEffect((prevState) => !prevState);
        refreshUserData();
        fetchWingoList(1, 10);
        setLoaded(true);
      }
    });
    return () => {
      socket.off('data-server-k3');
    };

  }, []);

  return (
    <>
       <Toast2
        message={toast.message}
        isVisible={toast.isVisible}
        onClose={() => setToast({ isVisible: false, message: "" })}
      />
      <div className="ludo-meter">
        <div
          className="d-flex align-center justify-between"
          style={{ padding: "5px" }}
        >
          <div
            className="d-flex align-center justify-center"
            style={{ gap: "10px" }}
          >
            <span className="period-name">Period</span>
            <div
              className="d-flex align-center justify-center htp"
              onClick={() => {
                $("#howtoPlayModelK3").removeClass("hide");
                $("#howtoPlayModelK3").addClass("show");
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17px"
                height="17px"
                viewBox="0 0 24 24"
              >
                <path
                  fill="#6485ff"
                  d="M14.727 6.727H14V0H4.91c-.905 0-1.637.732-1.637 1.636v20.728c0 .904.732 1.636 1.636 1.636h14.182c.904 0 1.636-.732 1.636-1.636V6.727zm-.545 10.455H7.09v-1.364h7.09v1.364zm2.727-3.273H7.091v-1.364h9.818zm0-3.273H7.091V9.273h9.818zM14.727 6h6l-6-6z"
                />
              </svg>
              <span>How to play</span>
            </div>
          </div>
          <span className="tr">Time Remaining</span>
        </div>
        <div
          className="d-flex align-center justify-between"
          style={{ padding: "5px" }}
        >
          <span className="preiod-id">{Number(nextPreiod)}</span>
          <div className="k3-counter-container">
            <span className="counter-num">{0}</span>
            <span className="counter-num">{minutetime2}</span>
            <span className="counter-num x">:</span>
            <span className="counter-num">{secondtime1}</span>
            <span className="counter-num">{secondtime2}</span>
          </div>
        </div>
        <div className="d-flex align-center justify-between pt-2">
          <div className="meter" onClick={randomize}>
            <div className="meter-inner">
              {numbers.map((num, index) => (
                <div className="num" key={index}>
                  <img src={ludos[num - 1]} alt={num} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="bet-section" style={{ padding: "0px" }}>
        <div
          className="bet-section-main"
          style={{ borderRadius: "0px 0px 10px 10px" }}
        >
          <div
            className="d-flex align-center justify-between"
            style={{ flexDirection: "column" }}
          >
            <div className="bet-tabs">
              {bet_tabs.map((tab, index) => (
                <div
                  key={tab.label}
                  className={`bet-tab ${
                    betActiveTab === tab.label ? "active" : ""
                  }`}
                  onClick={() => handleBetTabClick(tab.label)}
                >
                  {tab.label}
                </div>
              ))}
            </div>
            <div className="bet-tab-content" style={{ width: "100%" }}>
              {bet_tabs.find((tab) => tab.label === betActiveTab)?.content}
            </div>
          </div>
        </div>
        <div className="bet-section-model" style={{ marginTop: "0px" }}>
          <div className="counter1">{secondtime1}</div>
          <div className="counter2">{secondtime2}</div>
        </div>
      </div>
      <div className="history-tabs">
        {tabs.map((tab, index) => (
          <div
            key={tab.label}
            className={`history-tab ${activeTab === tab.label ? "active" : ""}`}
            onClick={() => handleTabClick(tab.label)}
          >
            {tab.label}
          </div>
        ))}
      </div>
      <div className="history-tab-content">
        {tabs.find((tab) => tab.label === activeTab)?.content}
      </div>
      <div className={`off-canvas ${isOpen ? "open" : ""}`}>
        <div className="off-canvas-container">
          <div className="off-canvas-content">
            <div className="k3-model-content1">
              <div className="k3-content-1">
                {betType === "total" && !isEmptyObject(bet) && (
                  <div className="display">
                    <span>Total :</span>
                    <div className="display-content">
                      {bet.selectedBalls.length > 0 &&
                        bet.selectedBalls.map((ball, index) => (
                          <span
                            key={index}
                            className={`total-ball ${
                              Number(ball) % 2 === 0 ? "text-green" : "text-red"
                            }`}
                            style={{
                              backgroundImage: `url(${
                                Number(ball) % 2 === 0 ? greenBallBg : redBallBg
                              })`,
                            }}
                          >
                            {ball}
                          </span>
                        ))}
                      {bet.selectedBigSmall && (
                        <span
                          className={`bs ${
                            bet.selectedBigSmall === "Big"
                              ? "bg-big"
                              : "bg-small"
                          }`}
                        >
                          {bet.selectedBigSmall}
                        </span>
                      )}
                      {bet.selectedOddEven && (
                        <span
                          className={`oe ${
                            bet.selectedOddEven === "Odd"
                              ? "bg-red"
                              : "bg-green"
                          }`}
                        >
                          {bet.selectedOddEven}
                        </span>
                      )}
                    </div>
                  </div>
                )}

                {betType === "two_same" && !isEmptyObject(bet) && (
                  <>
                    {bet.selectedMatches.length > 0 && (
                      <div className="display">
                        <span>2 matching numbers :</span>
                        <div className="display-content">
                          {bet.selectedMatches.map((item, index) => (
                            <span key={index} className="bs bg-violet">
                              {item}
                            </span>
                          ))}
                        </div>
                      </div>
                    )}
                    {bet.selectedPairRed.length > 0 &&
                      bet.selectedPairGreen.length > 0 && (
                        <div className="display">
                          <span>A unique number :</span>
                          <div className="display-content">
                            <div className="unique">
                              {bet.selectedPairRed.length > 0 &&
                                bet.selectedPairGreen.length > 0 && (
                                  <div className="d-flex">
                                    {bet.selectedPairRed.map((item, index) => (
                                      <span className="un" key={index}>
                                        <span className="un1">{item}</span>
                                        <span className="un2">
                                          {bet.selectedPairGreen.join(",")}
                                        </span>
                                      </span>
                                    ))}
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      )}
                  </>
                )}

                {betType === "three_same" && !isEmptyObject(bet) && (
                  <>
                    {bet.selectedMatches.length > 0 && (
                      <div className="display">
                        <span>3 of the same number:</span>
                        <div className="display-content">
                          {bet.selectedMatches.map((item, index) => (
                            <span key={index} className="bs bg-violet">
                              {item}
                            </span>
                          ))}
                        </div>
                      </div>
                    )}
                    {bet.threeOdd && (
                      <div className="display">
                        <span>Any 3 of the same number:</span>
                        <div className="display-content">
                          <div className="bs bg-red">
                            Any 3 of the same number: odds
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )}

                {betType === "different" && !isEmptyObject(bet) && (
                  <>
                    {bet.threeDiffOdd.length > 0 && (
                      <div className="display">
                        <span>3 of the same number:</span>
                        <div className="display-content">
                          {bet.threeDiffOdd.map((item, index) => (
                            <span key={index} className="bs bg-violet">
                              {item}
                            </span>
                          ))}
                        </div>
                      </div>
                    )}
                    {bet.threeContinous && (
                      <div className="display">
                        <span>3 continuous numbers</span>
                        <div className="display-content">
                          <div className="bs bg-red">3 continuous numbers</div>
                        </div>
                      </div>
                    )}
                    {bet.twoDiffOdd.length > 0 && (
                      <div className="display">
                        <span>2 different numbers:</span>
                        <div className="display-content">
                          {bet.twoDiffOdd.map((item, index) => (
                            <span key={index} className="bs bg-violet">
                              {item}
                            </span>
                          ))}
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="content2">
              <div className="content2-balance">
                <span>Balance</span>
                <div className="d-flex">
                  {[1, 10, 100, 1000].map((val) => (
                    <div
                      key={val}
                      className={`x-box ${
                        balance === val ? "active " + bg : ""
                      }`}
                      onClick={() => handleBalance(val)}
                    >
                      {val}
                    </div>
                  ))}
                </div>
              </div>
              <div className="content2-qty">
                <span>Quantity</span>
                <div className="d-flex">
                  <div
                    className={`x-box btn ${bg}`}
                    onClick={() => handleMlt(false)}
                  >
                    -
                  </div>
                  <div className="x-box mlt">
                    <input
                      type="number"
                      className="mlt-input"
                      value={mltValue}
                      readOnly={true}
                      onChange={handleInput}
                      min="1"
                      max="9999"
                    />
                  </div>
                  <div
                    className={`x-box btn ${bg}`}
                    onClick={() => handleMlt(true)}
                  >
                    +
                  </div>
                </div>
              </div>
              <div className="content2-mlt">
                <div className="d-flex">
                  {[1, 5, 10, 20, 50, 100].map((multiplier) => (
                    <div
                      key={multiplier}
                      className={`x-box ${
                        mltValue === multiplier ? "active " + bg : ""
                      }`}
                      onClick={() => handleMlt2(multiplier)}
                    >
                      X{multiplier}
                    </div>
                  ))}
                </div>
              </div>
              <div className="content2-policy">
                <div className="d-flex align-center" style={{ gap: "10px" }}>
                  <div
                    className="d-flex align-center"
                    style={{ gap: "10px" }}
                    onClick={handleAgree}
                  >
                    <img
                      src={agree ? checkImg : checkCircle}
                      alt=""
                      className="check-img"
                    />
                    <span>I agree</span>
                  </div>
                  <span
                    className="policy-btn"
                    onClick={() => {
                      $("#agreeModel").removeClass("hide");
                      $("#agreeModel").addClass("show");
                    }}
                  >
                    《Pre-sale rules》
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="off-canvas-footer">
            <div
              className="cancel"
              onClick={() => toggleOffCanvas(false, null, null)}
            >
              Cancel
            </div>
            <div className={`amt ${bg}`} onClick={confirmBet}>
              Total amount {formatMoney(betAmount)}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default K35;

// History Tab Component

const GameHistory = ({ triggerEffect }) => {
  const [wingoList, setWingoList] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [pageNoTo, setPageNoTo] = useState(10);
  const [pageCount, setPageCount] = useState(0);

  const ludoImages = [ludo1, ludo2, ludo3, ludo4, ludo5, ludo6];

  const fetchWingoList = async (page, pageto) => {
    try {
      const response = await getK3List(5, page, pageto);
      if (response.data.status) {
        setWingoList(response?.data?.data?.gameslist);
        setPageCount(response.data.page);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const nextPage = () => {
    if (pageCount === pageNo) {
      return;
    }
    setPageNo((prevPageNo) => prevPageNo + 10);
    setPageNoTo((prevPageNo) => prevPageNo + 10);
    fetchWingoList(pageNo + 10, pageNoTo + 10);
  };

  const prevPage = () => {
    if (pageNo <= 0) {
      return;
    }
    setPageNo((prevPageNo) => prevPageNo - 10);
    setPageNoTo((prevPageNo) => prevPageNo - 10);
    fetchWingoList(pageNo - 10, pageNoTo - 10);
  };
  const renderCircles = (colorResult) => {
    const colors = colorResult.split(",");
    return (
      <>
        {colors.map((color, index) => (
          <div
            key={index}
            className={`circle circle-${color.trim()} me-1`}
          ></div>
        ))}
      </>
    );
  };
  const getNumClass = (numResult, color) => {
    if (numResult === "0") {
      return "text-rv";
    } else if (numResult === "5") {
      return "text-gv";
    } else {
      return `text-${color}`;
    }
  };

  useEffect(() => {
    fetchWingoList(1, 10);
    socket.on("data-server-k3", async (data) => {
      fetchWingoList(1, 10);
    });
    return () => {
      socket.off('data-server-k3');
    };
  }, [triggerEffect]);
  return (
    <>
      <div className="game-hsitory">
        <div className="game-history-header">
          <span>Period</span>
          <span>Sum</span>
          <span>Results</span>
        </div>
        <div className="game-history-content">
        {wingoList.map((item, index) => (
            <div className="history-item" key={index}>
              <span>{item.period}</span>
              <span
                style={{
                  fontFamily: "arial",
                  fontWeight: "normal",
                  fontSize: "1rem",
                  color: "var(--mainTex)",
                }}
              >
                {(String(item.result).split('').reduce((acc, digit) => acc + parseInt(digit), 0))}
              </span>
              <span>
                {(String(item.result).split('').reduce((acc, digit) => acc + parseInt(digit), 0)) > 10 ? "Big" : "Small"}
                <div style={{ width: "20px" }}></div>
                {(String(item.result).split('').reduce((acc, digit) => acc + parseInt(digit), 0)) % 2 == 0 ? "Even" : "Odd"}
              </span>
              <span>
                <div className="h-ludo-result">

                  {Array.from(String(item?.result), Number).map((value, index) => (
                    <img
                      src={ludoImages[Number(value) - 1]}
                      alt={index}
                      key={index}
                    />
                  ))}
                </div>
              </span>
            </div>
          ))}
        </div>
      </div>
      <div className="game-history-footer">
        <div
          className={`prev ${pageNo > 0 ? "active" : ""}`}
          onClick={() => prevPage()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25px"
            height="25px"
            viewBox="0 0 24 24"
          >
            <path
              fill="#768096"
              d="M13.83 19a1 1 0 0 1-.78-.37l-4.83-6a1 1 0 0 1 0-1.27l5-6a1 1 0 0 1 1.54 1.28L10.29 12l4.32 5.36a1 1 0 0 1-.78 1.64"
            />
          </svg>
        </div>
        <div className="pages">
          <span>{pageNoTo/10}</span>/<span>{pageCount }</span>
        </div>
        <div
          className={`next ${pageNo < pageCount ? "active" : ""}`}
          onClick={() => nextPage()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25px"
            height="25px"
            viewBox="0 0 24 24"
          >
            <path
              fill="#fff"
              d="M10 19a1 1 0 0 1-.64-.23a1 1 0 0 1-.13-1.41L13.71 12L9.39 6.63a1 1 0 0 1 .15-1.41a1 1 0 0 1 1.46.15l4.83 6a1 1 0 0 1 0 1.27l-5 6A1 1 0 0 1 10 19"
            />
          </svg>
        </div>
      </div>
    </>
  );
};

const Chart = ({ triggerEffect }) => {
  const canvasRef = useRef("null");
  const [wingoList, setWingoList] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [pageNoTo, setPageNoTo] = useState(10);
  const [pageCount, setPageCount] = useState(0);

  const ludoImages = [ludo1, ludo2, ludo3, ludo4, ludo5, ludo6];
  const fetchWingoList = async (page, pageto) => {
    try {
      const response = await getK3List(5, page, pageto);
      if (response.data.status) {
        setWingoList(response.data.data.gameslist);
        setPageCount(response.data.page);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const nextPage = () => {
    if (pageCount === pageNo) {
      return;
    }
    setPageNo((prevPageNo) => prevPageNo + 10);
    setPageNoTo((prevPageNo) => prevPageNo + 10);
    fetchWingoList(pageNo + 10, pageNoTo + 10);
  };

  const prevPage = () => {
    if (pageNo <= 0) {
      return;
    }
    setPageNo((prevPageNo) => prevPageNo - 10);
    setPageNoTo((prevPageNo) => prevPageNo - 10);
    fetchWingoList(pageNo - 10, pageNoTo - 10);
  };


  useEffect(() => {
    fetchWingoList(1, 10);
  }, [triggerEffect]);
  return (
    <>
      <div className="chart-history">
        <div className="chart-history-header">
          <span>Period</span>
          <span style={{ width: "30%" }}>Results</span>
          <span style={{ width: "30%" }}>Number</span>
        </div>
        <div className="chart-history-content">
        {wingoList.map((item, index) => (
            <div className="chart-item" key={index}>
              <span>{item.period}</span>
              <span style={{ width: "30%" }}>
                <div className="ludo-result">
                  {Array.from(String(item?.result), Number).map((value, index) => (
                    <img src={ludoImages[Number(value) - 1]} alt={index} />
                  ))}
                </div>
              </span>
              <span style={{ width: "30%", fontSize: ".8rem" }}>
                {/* {checkNumbers(String(item?.result))} */}
                {new Set(String(item.result)).size == 1 ? "3 same numbers" :
                  new Set(String(item.result)).size == 2 ? "2 same numbers" :
                    new Set(String(item.result)).size == 3 ? "3 different numbers" :
                      item.result}
              </span>
            </div>
          ))}
        </div>
      </div>
      <div className="chart-history-footer">
        <div
          className={`prev ${pageNo > 0 ? "active" : ""}`}
          onClick={prevPage}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25px"
            height="25px"
            viewBox="0 0 24 24"
          >
            <path
              fill="#768096"
              d="M13.83 19a1 1 0 0 1-.78-.37l-4.83-6a1 1 0 0 1 0-1.27l5-6a1 1 0 0 1 1.54 1.28L10.29 12l4.32 5.36a1 1 0 0 1-.78 1.64"
            />
          </svg>
        </div>
        <div className="pages">
          <span>{pageNoTo/10}</span>/<span>{pageCount}</span>
        </div>
        <div
          className={`next ${pageNo < pageCount ? "active" : ""}`}
          onClick={nextPage}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25px"
            height="25px"
            viewBox="0 0 24 24"
          >
            <path
              fill="#fff"
              d="M10 19a1 1 0 0 1-.64-.23a1 1 0 0 1-.13-1.41L13.71 12L9.39 6.63a1 1 0 0 1 .15-1.41a1 1 0 0 1 1.46.15l4.83 6a1 1 0 0 1 0 1.27l-5 6A1 1 0 0 1 10 19"
            />
          </svg>
        </div>
      </div>
    </>
  );
};

const MyHistory = ({ triggerEffect }) => {
  const [history, setHistory] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [pageNoTo, setPageNoTo] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [details, setDetails] = useState(null)
  const fetchMyHistory = async (page, pageto) => {
    try {
      const response = await getK3BetHistory(page, pageto, 5);
      if (response.data.status) {
        setHistory(response.data.data.gameslist);
        setPageCount(response.data.page);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const nextPage = () => {
    if (pageCount === pageNo) {
      return;
    }
    setPageNo((prevPageNo) => prevPageNo + 10);
    setPageNoTo((prevPageNo) => prevPageNo + 10);
    fetchMyHistory(pageNo + 10, pageNoTo + 10);
  };

  const prevPage = () => {
    if (pageNo <= 0) {
      return;
    }
    setPageNo((prevPageNo) => prevPageNo - 10);
    setPageNoTo((prevPageNo) => prevPageNo - 10);
    fetchMyHistory(pageNo - 10, pageNoTo - 10);
  };
  useEffect(() => {
    fetchMyHistory(1, 10);
  }, [triggerEffect]);

  const ludoImages = [ludo1, ludo2, ludo3, ludo4, ludo5, ludo6];
  const handleDetail = (i) => {
    if (details === i) {
      return setDetails(null)
    }
    setDetails(i)
  }
 const [toast, setToast] = useState({
          isVisible: false,
          message: "",
        });
      const showToast = (message) => {
        setToast({ isVisible: true, message });
        setTimeout(() => {
          setToast({ isVisible: false, message: "" });
        }, 3000); // Hide after 3 seconds
      };

  const copyToClipboard = (number) => {
    navigator.clipboard.writeText(String(number))
      .then(() => {
        showToast(
          `<svg width="25px" height="25px" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="iconify iconify--emojione-monotone" preserveAspectRatio="xMidYMid meet" fill="#fff" stroke="#fff"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M56 2L18.8 42.909L8 34.729H2L18.8 62L62 2z" fill="#fff"></path></g></svg>Copy successfully`
        );
      })
      .catch(err => {
        console.error('Failed to copy the text: ', err);
      });
  };


  const mappings = {
    s: "small",
    l: "odd",
    c: "even",
    b: "big"
  };
  return (
    <>
     <Toast
        message={toast.message}
        isVisible={toast.isVisible}
        onClose={() => setToast({ isVisible: false, message: "" })}
      />
      <div className="my-history">
        <div className="my-history-details">
          <div className="details-btn">
            Details
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20px"
              height="20px"
              viewBox="0 0 512 512"
            >
              <path
                fill="none"
                stroke="#6485ff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="32"
                d="M262.62 336L342 256l-79.38-80m68.35 80H170"
              />
              <path
                fill="none"
                stroke="#6485ff"
                strokeMiterlimit="10"
                strokeWidth="32"
                d="M256 448c106 0 192-86 192-192S362 64 256 64S64 150 64 256s86 192 192 192Z"
              />
            </svg>
          </div>
        </div>
        <div className="my-history-content">
        {history.length > 0 ? (
             history.map((item, i) => (
                          <div className="my-history-item k3" key={i}>
                            <div className='  flex items-center justify-between' onClick={() => handleDetail(i)}>
                              <div className='flex items-center'>
                                <div className={`flex text-sm justify-center items-center h-10 w-10 rounded-md me-1 
                                          ${(item.bet).split(',')[0] == "c" ? "bgs-green" : (item.bet).split(',')[0] == "l" ? "bgs-red-200" : (item.bet).split(',')[0] == "b" ? "color-yellow-bg-200" : "bgs-blue-500"}
                                           `}>
                                  {(item.bet).split(',')[0] == "c" ? "Even" : (item.bet).split(',')[0] == "l" ? "Odd" : (item.bet).split(',')[0] == "b" ? "Big" : (item.bet).split(',')[0] == "s" ? "Small" : (item.bet).split(',')[0]}
                                </div>
                                <div>
                                  <p className=" gray-50 text-md" style={{ marginBottom: "7px" }}>{item?.stage}</p>
                                  <p className='fs-sm gray-100'>{item.time}</p>
                                </div>
                              </div>
                              <div className="win-status">
                                {item.status === 1 ? (
                                  <>
                                    <div
                                      className="status-box"
                                      style={{ color: "#00ae50", borderColor: "#00ae50" }}
                                    >
                                      {"Succeed"}
                                    </div>
                                    <div
                                      className="result-amt"
                                      style={{ color: "#00ae50" }}
                                    >
                                      +{formatMoney(item.get)}
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    {item.status === 2 ? (
                                      <>
                                        <div
                                          className="status-box"
                                          style={{
                                            color: "#f95959",
                                            borderColor: "#f95959",
                                          }}
                                        >
                                          {"Failed"}
                                        </div>
                                        <div
                                          className="result-amt"
                                          style={{ color: "#f95959" }}
                                        >
                                          -{formatMoney(item.money)}
                                        </div>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
            
                            <div className={`mt-3 history-details ${details === i ? "active mb-5" : ""}`}>
                              <h2 className='heading-h2 gray-50 text-lg mb-1'>Details</h2>
                              <div className='flex items-center justify-between bg-color-l p-1 mb-1 rounded-md' style={{ fontSize: "15px" }}>
                                <span className=' gray-100 '>Order number</span>
                                <span className=' gray-100 flex item-center'>{item.id_product}<PiCopySimpleBold className='mt-[3px]' onClick={() => copyToClipboard(String(item.id_product))} /></span>
                              </div>
                              <div className='flex items-center justify-between bg-color-l p-1 mb-1 rounded-md'>
                                <span className=' gray-100 '>Period</span>
                                <span className=' gray-100 '>{item.stage}</span>
                              </div>
                              <div className='flex items-center justify-between bg-color-l p-1 mb-1  rounded-md'>
                                <span className=' gray-100 '>Purchase amount</span>
                                <span className=' gray-100 '>₹{item.money}</span>
                              </div>
                              <div className='flex items-center justify-between bg-color-l p-1 mb-1  rounded-md'>
                                <span className=' gray-100 '>Quantiy</span>
                                <span className=' gray-100 '>{item.amount}</span>
                              </div>
                              <div className='flex items-center justify-between bg-color-l p-1 mb-1  rounded-md'>
                                <span className='gray-100 '>Amount after tax</span>
                                <span className='color-red-200 '>₹{item.price}</span>
                              </div>
                              <div className='flex items-center justify-between bg-color-l p-1 mb-1  rounded-md'>
                                <span className='gray-100 '>Tax</span>
                                <span className='gray-100 '>₹{item.fee}</span>
                              </div>
                              <div className='flex items-center justify-between bg-color-l p-1 mb-1  rounded-md'>
                                <span className='gray-100 '>Result</span>
                                {item.status !== 0 && (
                                  <div className="ludo-result">
                                    {Array.from(String(item?.result), Number).map((value, index) => (
                                      <img src={ludoImages[Number(value) - 1]} alt={index} key={index} />
                                    ))}
                                  </div>
            
                                )}
                              </div>
                              <div className=' flex flex-col   bg-color-l p-1 mb-2  rounded-md'>
                                <p className='gray-100'>Select</p>
                                <span className=' gray-100 flex'>{item.typeGame}: {(item.bet).split(',')[0] == "c" ? "Even" : (item.bet).split(',')[0] == "l" ? "Odd" : (item.bet).split(',')[0] == "b" ? "Big" : (item.bet).split(',')[0] == "s" ? (item.bet).split(',').map(char => mappings[char]).join(' ') : item.bet}</span>
                              </div>
                              <div className='flex items-center justify-between bg-color-l p-1 mb-1  rounded-md'>
                                <span className=' gray-100 '>Status</span>
                                {item.status !== 0 && (
                                  <span className={` color-red-200 ${item.status == 1 ? "color-green" : "color-red-200"}`}> {item.status === 1 ? "Succeed" : " Failed"}</span>
                                )}
                              </div>
                              <div className='flex items-center justify-between bg-color-l p-1 mb-1  rounded-md'>
                                <span className=' gray-100 '>Win/loss</span>
                                {item.status !== 0 && (
                                  <span className={` ${item.status === 1 ? "color-green " : "color-red-200"}`}>
                                    {item.status === 1 ? ("+₹" + item.get) : ("-₹" + item.money)}
            
                                  </span>
                                )}
                              </div>
                              <div className='flex items-center justify-between bg-color-l p-1 mb-1  rounded-md'>
                                <span className=' gray-100 '>Order time</span>
                                <span className=' gray-100 '>{item.time}</span>
                              </div>
            
                            </div>
                          </div>
                        ))
          ) : (
            <div className="empty">
              <img src={noData} alt="" className="noData" />
              <span>No Data</span>
            </div>
          )}
        </div>
      </div>
      {history.length > 0 ? (
        <div className="my-history-footer">
          <div
            className={`prev ${pageNo > 0 ? "active" : ""}`}
            onClick={prevPage}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25px"
              height="25px"
              viewBox="0 0 24 24"
            >
              <path
                fill="#768096"
                d="M13.83 19a1 1 0 0 1-.78-.37l-4.83-6a1 1 0 0 1 0-1.27l5-6a1 1 0 0 1 1.54 1.28L10.29 12l4.32 5.36a1 1 0 0 1-.78 1.64"
              />
            </svg>
          </div>
          <div className="pages">
            <span>{pageNoTo/10}</span>/
            <span>{pageCount > 0 ? pageCount : 1}</span>
          </div>
          <div
            className={`next ${pageNo < pageCount ? "active" : ""}`}
            onClick={nextPage}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25px"
              height="25px"
              viewBox="0 0 24 24"
            >
              <path
                fill="#fff"
                d="M10 19a1 1 0 0 1-.64-.23a1 1 0 0 1-.13-1.41L13.71 12L9.39 6.63a1 1 0 0 1 .15-1.41a1 1 0 0 1 1.46.15l4.83 6a1 1 0 0 1 0 1.27l-5 6A1 1 0 0 1 10 19"
              />
            </svg>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

// Bet Tabs Component

const TotalBet = ({ betOpen, triggerEffect }) => {
  const [selectedBalls, setSelectedBalls] = useState([]);
  const [selectedBigSmall, setSelectedBigSmall] = useState(null);
  const [selectedOddEven, setSelectedOddEven] = useState(null);
  const [click, setClick] = useState(false);

  useEffect(() => {
    const checkBet = () => {
      return (
        selectedBalls.length > 0 ||
        selectedBigSmall !== null ||
        selectedOddEven !== null
      );
    };

    betOpen(
      checkBet(),
      { selectedBigSmall, selectedOddEven, selectedBalls },
      "total"
    );
  }, [click]);

  const handleBallClick = (value) => {
    setSelectedBalls((prev) =>
      prev.includes(value) ? prev.filter((v) => v !== value) : [...prev, value]
    );
    setClick(!click);
  };

  const handleBigSmallClick = (type) => {
    setSelectedBigSmall((prev) => (prev === type ? null : type));
    setClick(!click);
  };

  const handleOddEvenClick = (type) => {
    setSelectedOddEven((prev) => (prev === type ? null : type));
    setClick(!click);
  };

  return (
    <div className="total-bet">
      <div className="total-bet-balls">
        {[
          "207.36",
          "69.12",
          "34.56",
          "20.74",
          "13.83",
          "9.88",
          "8.3",
          "7.68",
          "7.68",
          "8.3",
          "9.88",
          "13.83",
          "20.74",
          "34.56",
          "69.12",
          "207.36",
        ].map((item, index) => {
          const value = index + 3;
          const isGreen = value % 2 === 0;
          return (
            <div
              key={index}
              className={`bet-ball ${
                selectedBalls.includes(value) ? "active" : ""
              }`}
              onClick={() => handleBallClick(value)}
            >
              <div
                className={`image ${isGreen ? "text-green" : "text-red"}`}
                style={{
                  backgroundImage: `url(${isGreen ? greenBallBg : redBallBg})`,
                }}
              >
                {value}
              </div>
              <span>{item}X</span>
            </div>
          );
        })}
      </div>
      <div className="total-bet-size">
        <div
          className={`bet-size-box bg-big ${
            selectedBigSmall === "Big" ? "active" : ""
          }`}
          onClick={() => handleBigSmallClick("Big")}
        >
          <span>Big</span>
          <span>2X</span>
        </div>
        <div
          className={`bet-size-box bg-small ${
            selectedBigSmall === "Small" ? "active" : ""
          }`}
          onClick={() => handleBigSmallClick("Small")}
        >
          <span>Small</span>
          <span>2X</span>
        </div>
        <div
          className={`bet-size-box bg-red ${
            selectedOddEven === "Odd" ? "active" : ""
          }`}
          onClick={() => handleOddEvenClick("Odd")}
        >
          <span>Odd</span>
          <span>2X</span>
        </div>
        <div
          className={`bet-size-box bg-green ${
            selectedOddEven === "Even" ? "active" : ""
          }`}
          onClick={() => handleOddEvenClick("Even")}
        >
          <span>Even</span>
          <span>2X</span>
        </div>
      </div>
    </div>
  );
};

const TwoSame = ({ betOpen, triggerEffect }) => {
  const [selectedMatches, setSelectedMatches] = useState([]);
  const [selectedPairRed, setSelectedPairRed] = useState([]);
  const [selectedPairGreen, setSelectedPairGreen] = useState([]);
  const [click, setClick] = useState(false);
  const matchings = [
    { match: "11" },
    { match: "22" },
    { match: "33" },
    { match: "44" },
    { match: "55" },
    { match: "66" },
  ];
  const pairred = [
    { match: "11" },
    { match: "22" },
    { match: "33" },
    { match: "44" },
    { match: "55" },
    { match: "66" },
  ];
  const pairgreen = [
    { match: "1" },
    { match: "2" },
    { match: "3" },
    { match: "4" },
    { match: "5" },
    { match: "6" },
  ];

  useEffect(() => {
    const checkBet = () => {
      return (
        selectedMatches.length > 0 ||
        (selectedPairRed.length > 0 && selectedPairGreen.length > 0)
      );
    };

    betOpen(
      checkBet(),
      {
        selectedPairRed,
        selectedPairGreen,
        selectedMatches,
      },
      "two_same"
    );
  }, [click]);

  const handleMatchClick = (item) => {
    setSelectedMatches((prev) =>
      prev.includes(item.match)
        ? prev.filter((m) => m !== item.match)
        : [...prev, item.match]
    );
    setClick(!click);
  };

  const handlePairRedClick = (item) => {
    const greenMatch = item.match[1];
    setSelectedPairGreen((prev) => prev.filter((m) => m !== greenMatch));
    setSelectedPairRed((prev) =>
      prev.includes(item.match)
        ? prev.filter((m) => m !== item.match)
        : [...prev, item.match]
    );
    setClick(!click);
  };

  const handlePairGreenClick = (item) => {
    const redMatch = pairred.find(
      (redItem) => redItem.match[1] === item.match
    ).match;
    setSelectedPairRed((prev) => prev.filter((m) => m !== redMatch));
    setSelectedPairGreen((prev) =>
      prev.includes(item.match)
        ? prev.filter((m) => m !== item.match)
        : [...prev, item.match]
    );
    setClick(!click);
  };
  const resetValues = () => {
    setSelectedMatches([]);
    setSelectedPairRed([]);
    setSelectedPairGreen([]);
  };

  useEffect(() => {
    resetValues();
  }, [triggerEffect]);

  return (
    <div className="two-same">
      <div className="two-matching-container">
        <div className="two-matching-heading">
          <span>2 matching numbers: odds(13.83)</span>
          <span className="info-icon">?</span>
        </div>
        <div className="two-matching-body">
          {matchings.map((item, index) => (
            <div
              className={`two-matching ${
                selectedMatches.includes(item.match) ? "active" : ""
              }`}
              key={index}
              onClick={() => handleMatchClick(item)}
            >
              {item.match}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="17"
                viewBox="0 0 30 30"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M25.0566 25.0562C22.4829 27.6299 18.9273 29.2218 15 29.2218C11.0727 29.2218 7.51711 27.6299 4.94336 25.0562C2.36965 22.4824 0.777771 18.9269 0.777771 14.9996C0.777771 11.0722 2.36965 7.51668 4.94336 4.94293C7.51711 2.36922 11.0727 0.777344 15 0.777344C18.9273 0.777344 22.4829 2.36922 25.0566 4.94293C27.6303 7.51668 29.2222 11.0722 29.2222 14.9996C29.2222 18.9269 27.6303 22.4824 25.0566 25.0562ZM22.7492 11.1747C23.091 10.8222 23.0823 10.2595 22.7299 9.91775C22.3775 9.576 21.8147 9.58465 21.473 9.93709L12.6296 17.279L8.52702 14.826C8.18527 14.4735 7.62252 14.4649 7.27009 14.8066C6.91766 15.1484 6.909 15.7111 7.25076 16.0636L11.9915 20.9525C12.1589 21.1251 12.3891 21.2225 12.6296 21.2225C12.8701 21.2225 13.1003 21.1251 13.2678 20.9525L22.7492 11.1747Z"
                  fill="white"
                />
              </svg>
            </div>
          ))}
        </div>
      </div>
      <div className="pair-matching-container">
        <div className="pair-matching-heading">
          <span>A pair of unique numbers: odds(69.12)</span>
          <span className="info-icon">?</span>
        </div>
        <div className="pair-matching-body red">
          {pairred.map((item, index) => (
            <div
              className={`pair-matching red ${
                selectedPairRed.includes(item.match) ? "active" : ""
              }`}
              key={index}
              onClick={() => handlePairRedClick(item)}
            >
              {item.match}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="17"
                viewBox="0 0 30 30"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M25.0566 25.0562C22.4829 27.6299 18.9273 29.2218 15 29.2218C11.0727 29.2218 7.51711 27.6299 4.94336 25.0562C2.36965 22.4824 0.777771 18.9269 0.777771 14.9996C0.777771 11.0722 2.36965 7.51668 4.94336 4.94293C7.51711 2.36922 11.0727 0.777344 15 0.777344C18.9273 0.777344 22.4829 2.36922 25.0566 4.94293C27.6303 7.51668 29.2222 11.0722 29.2222 14.9996C29.2222 18.9269 27.6303 22.4824 25.0566 25.0562ZM22.7492 11.1747C23.091 10.8222 23.0823 10.2595 22.7299 9.91775C22.3775 9.576 21.8147 9.58465 21.473 9.93709L12.6296 17.279L8.52702 14.826C8.18527 14.4735 7.62252 14.4649 7.27009 14.8066C6.91766 15.1484 6.909 15.7111 7.25076 16.0636L11.9915 20.9525C12.1589 21.1251 12.3891 21.2225 12.6296 21.2225C12.8701 21.2225 13.1003 21.1251 13.2678 20.9525L22.7492 11.1747Z"
                  fill="white"
                />
              </svg>
            </div>
          ))}
        </div>
        <div className="pair-matching-body green">
          {pairgreen.map((item, index) => (
            <div
              className={`pair-matching green ${
                selectedPairGreen.includes(item.match) ? "active" : ""
              }`}
              key={index}
              onClick={() => handlePairGreenClick(item)}
            >
              {item.match}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="17"
                viewBox="0 0 30 30"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M25.0566 25.0562C22.4829 27.6299 18.9273 29.2218 15 29.2218C11.0727 29.2218 7.51711 27.6299 4.94336 25.0562C2.36965 22.4824 0.777771 18.9269 0.777771 14.9996C0.777771 11.0722 2.36965 7.51668 4.94336 4.94293C7.51711 2.36922 11.0727 0.777344 15 0.777344C18.9273 0.777344 22.4829 2.36922 25.0566 4.94293C27.6303 7.51668 29.2222 11.0722 29.2222 14.9996C29.2222 18.9269 27.6303 22.4824 25.0566 25.0562ZM22.7492 11.1747C23.091 10.8222 23.0823 10.2595 22.7299 9.91775C22.3775 9.576 21.8147 9.58465 21.473 9.93709L12.6296 17.279L8.52702 14.826C8.18527 14.4735 7.62252 14.4649 7.27009 14.8066C6.91766 15.1484 6.909 15.7111 7.25076 16.0636L11.9915 20.9525C12.1589 21.1251 12.3891 21.2225 12.6296 21.2225C12.8701 21.2225 13.1003 21.1251 13.2678 20.9525L22.7492 11.1747Z"
                  fill="white"
                />
              </svg>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const ThreeSame = ({ betOpen, triggerEffect }) => {
  const [selectedMatches, setSelectedMatches] = useState([]);
  const [threeOdd, setThreeOdd] = useState(false);
  const [click, setClick] = useState(false);
  const matchings = [
    { match: "111" },
    { match: "222" },
    { match: "333" },
    { match: "444" },
    { match: "555" },
    { match: "666" },
  ];

  useEffect(() => {
    const checkBet = () => {
      return selectedMatches.length > 0 || threeOdd;
    };

    betOpen(
      checkBet(),
      {
        selectedMatches,
        threeOdd,
      },
      "three_same"
    );
  }, [click]);

  const handleMatchClick = (item) => {
    setSelectedMatches((prev) =>
      prev.includes(item.match)
        ? prev.filter((m) => m !== item.match)
        : [...prev, item.match]
    );
    setClick(!click);
  };
  const handleThreeSame = (item) => {
    setThreeOdd(!threeOdd);
    setClick(!click);
  };

  const resetValues = () => {
    setSelectedMatches([]);
  };

  useEffect(() => {
    resetValues();
  }, [triggerEffect]);

  return (
    <div className="two-same">
      <div className="two-matching-container">
        <div className="two-matching-heading">
          <span>3 of the same number: odds (207.36)</span>
          <span className="info-icon">?</span>
        </div>
        <div className="two-matching-body">
          {matchings.map((item, index) => (
            <div
              className={`two-matching ${
                selectedMatches.includes(item.match) ? "active" : ""
              }`}
              key={index}
              onClick={() => handleMatchClick(item)}
            >
              {item.match}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="17"
                viewBox="0 0 30 30"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M25.0566 25.0562C22.4829 27.6299 18.9273 29.2218 15 29.2218C11.0727 29.2218 7.51711 27.6299 4.94336 25.0562C2.36965 22.4824 0.777771 18.9269 0.777771 14.9996C0.777771 11.0722 2.36965 7.51668 4.94336 4.94293C7.51711 2.36922 11.0727 0.777344 15 0.777344C18.9273 0.777344 22.4829 2.36922 25.0566 4.94293C27.6303 7.51668 29.2222 11.0722 29.2222 14.9996C29.2222 18.9269 27.6303 22.4824 25.0566 25.0562ZM22.7492 11.1747C23.091 10.8222 23.0823 10.2595 22.7299 9.91775C22.3775 9.576 21.8147 9.58465 21.473 9.93709L12.6296 17.279L8.52702 14.826C8.18527 14.4735 7.62252 14.4649 7.27009 14.8066C6.91766 15.1484 6.909 15.7111 7.25076 16.0636L11.9915 20.9525C12.1589 21.1251 12.3891 21.2225 12.6296 21.2225C12.8701 21.2225 13.1003 21.1251 13.2678 20.9525L22.7492 11.1747Z"
                  fill="white"
                />
              </svg>
            </div>
          ))}
        </div>
      </div>
      <div className="two-matching-container">
        <div className="two-matching-heading">
          <span>Any 3 of the same number: odds (34.56)</span>
          <span className="info-icon">?</span>
        </div>
        <div className="two-matching-body">
          <div
            className={`three-same-odd ${threeOdd ? "active" : ""}`}
            onClick={handleThreeSame}
          >
            Any 3 of the same number: odds
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="17"
              viewBox="0 0 30 30"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M25.0566 25.0562C22.4829 27.6299 18.9273 29.2218 15 29.2218C11.0727 29.2218 7.51711 27.6299 4.94336 25.0562C2.36965 22.4824 0.777771 18.9269 0.777771 14.9996C0.777771 11.0722 2.36965 7.51668 4.94336 4.94293C7.51711 2.36922 11.0727 0.777344 15 0.777344C18.9273 0.777344 22.4829 2.36922 25.0566 4.94293C27.6303 7.51668 29.2222 11.0722 29.2222 14.9996C29.2222 18.9269 27.6303 22.4824 25.0566 25.0562ZM22.7492 11.1747C23.091 10.8222 23.0823 10.2595 22.7299 9.91775C22.3775 9.576 21.8147 9.58465 21.473 9.93709L12.6296 17.279L8.52702 14.826C8.18527 14.4735 7.62252 14.4649 7.27009 14.8066C6.91766 15.1484 6.909 15.7111 7.25076 16.0636L11.9915 20.9525C12.1589 21.1251 12.3891 21.2225 12.6296 21.2225C12.8701 21.2225 13.1003 21.1251 13.2678 20.9525L22.7492 11.1747Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};

const Different = ({ betOpen, triggerEffect }) => {
  const [threeDiffOdd, setThreeDiffOdd] = useState([]);
  const [twoDiffOdd, setTwoDiffOdd] = useState([]);
  const [threeContinous, setThreeContinous] = useState(false);
  const [click, setClick] = useState(false);
  const matchings = [
    { match: "1" },
    { match: "2" },
    { match: "3" },
    { match: "4" },
    { match: "5" },
    { match: "6" },
  ];

  useEffect(() => {
    const checkBet = () => {
      return threeDiffOdd.length === 3 || twoDiffOdd.length === 2 || threeContinous;
    };

    betOpen(
      checkBet(),
      {
        threeDiffOdd,
        twoDiffOdd,
        threeContinous,
      },
      "different"
    );
  }, [click]);

  const handleThreeDiffOddClick = (item) => {
    setThreeDiffOdd((prev) => {
      if (prev.includes(item.match)) {
        return prev.filter((m) => m !== item.match);
      } else if (prev.length >= 3) {
        return [...prev.slice(1), item.match];
      } else {
        return [...prev, item.match];
      }
    });
    setClick(!click);
  };

  const handleTwoDiffOddClick = (item) => {
    setTwoDiffOdd((prev) => {
      if (prev.includes(item.match)) {
        return prev.filter((m) => m !== item.match);
      } else if (prev.length >= 2) {
        return [...prev.slice(1), item.match];
      } else {
        return [...prev, item.match];
      }
    });
    setClick(!click);
  };
  const handleThreeContinousClick = () => {
    setThreeContinous(!threeContinous);
    setClick(!click);
  };

  const resetValues = () => {
    setThreeDiffOdd([]);
    setTwoDiffOdd([]);
    setThreeContinous(false);
  };

  useEffect(() => {
    resetValues();
  }, [triggerEffect]);

  return (
    <div className="two-same">
      <div className="two-matching-container">
        <div className="two-matching-heading">
          <span>3 different numbers: odds (34.56)</span>
          <span className="info-icon">?</span>
        </div>
        <div className="two-matching-body">
          {matchings.map((item, index) => (
            <div
              className={`two-matching ${
                threeDiffOdd.includes(item.match) ? "active" : ""
              }`}
              key={index}
              onClick={() => handleThreeDiffOddClick(item)}
            >
              {item.match}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="17"
                viewBox="0 0 30 30"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M25.0566 25.0562C22.4829 27.6299 18.9273 29.2218 15 29.2218C11.0727 29.2218 7.51711 27.6299 4.94336 25.0562C2.36965 22.4824 0.777771 18.9269 0.777771 14.9996C0.777771 11.0722 2.36965 7.51668 4.94336 4.94293C7.51711 2.36922 11.0727 0.777344 15 0.777344C18.9273 0.777344 22.4829 2.36922 25.0566 4.94293C27.6303 7.51668 29.2222 11.0722 29.2222 14.9996C29.2222 18.9269 27.6303 22.4824 25.0566 25.0562ZM22.7492 11.1747C23.091 10.8222 23.0823 10.2595 22.7299 9.91775C22.3775 9.576 21.8147 9.58465 21.473 9.93709L12.6296 17.279L8.52702 14.826C8.18527 14.4735 7.62252 14.4649 7.27009 14.8066C6.91766 15.1484 6.909 15.7111 7.25076 16.0636L11.9915 20.9525C12.1589 21.1251 12.3891 21.2225 12.6296 21.2225C12.8701 21.2225 13.1003 21.1251 13.2678 20.9525L22.7492 11.1747Z"
                  fill="white"
                />
              </svg>
            </div>
          ))}
        </div>
      </div>

      <div className="two-matching-container">
        <div className="two-matching-heading">
          <span>3 continuous numbers: odds (8.64)</span>
          <span className="info-icon">?</span>
        </div>
        <div className="two-matching-body">
          <div
            className={`three-same-odd ${threeContinous ? "active" : ""}`}
            onClick={handleThreeContinousClick}
          >
            3 continuous numbers
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="17"
              viewBox="0 0 30 30"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M25.0566 25.0562C22.4829 27.6299 18.9273 29.2218 15 29.2218C11.0727 29.2218 7.51711 27.6299 4.94336 25.0562C2.36965 22.4824 0.777771 18.9269 0.777771 14.9996C0.777771 11.0722 2.36965 7.51668 4.94336 4.94293C7.51711 2.36922 11.0727 0.777344 15 0.777344C18.9273 0.777344 22.4829 2.36922 25.0566 4.94293C27.6303 7.51668 29.2222 11.0722 29.2222 14.9996C29.2222 18.9269 27.6303 22.4824 25.0566 25.0562ZM22.7492 11.1747C23.091 10.8222 23.0823 10.2595 22.7299 9.91775C22.3775 9.576 21.8147 9.58465 21.473 9.93709L12.6296 17.279L8.52702 14.826C8.18527 14.4735 7.62252 14.4649 7.27009 14.8066C6.91766 15.1484 6.909 15.7111 7.25076 16.0636L11.9915 20.9525C12.1589 21.1251 12.3891 21.2225 12.6296 21.2225C12.8701 21.2225 13.1003 21.1251 13.2678 20.9525L22.7492 11.1747Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
      </div>
      <div className="two-matching-container">
        <div className="two-matching-heading">
          <span>2 different numbers: odds (6.91)</span>
          <span className="info-icon">?</span>
        </div>
        <div className="two-matching-body">
          {matchings.map((item, index) => (
            <div
              className={`two-matching ${
                twoDiffOdd.includes(item.match) ? "active" : ""
              }`}
              key={index}
              onClick={() => handleTwoDiffOddClick(item)}
            >
              {item.match}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="17"
                viewBox="0 0 30 30"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M25.0566 25.0562C22.4829 27.6299 18.9273 29.2218 15 29.2218C11.0727 29.2218 7.51711 27.6299 4.94336 25.0562C2.36965 22.4824 0.777771 18.9269 0.777771 14.9996C0.777771 11.0722 2.36965 7.51668 4.94336 4.94293C7.51711 2.36922 11.0727 0.777344 15 0.777344C18.9273 0.777344 22.4829 2.36922 25.0566 4.94293C27.6303 7.51668 29.2222 11.0722 29.2222 14.9996C29.2222 18.9269 27.6303 22.4824 25.0566 25.0562ZM22.7492 11.1747C23.091 10.8222 23.0823 10.2595 22.7299 9.91775C22.3775 9.576 21.8147 9.58465 21.473 9.93709L12.6296 17.279L8.52702 14.826C8.18527 14.4735 7.62252 14.4649 7.27009 14.8066C6.91766 15.1484 6.909 15.7111 7.25076 16.0636L11.9915 20.9525C12.1589 21.1251 12.3891 21.2225 12.6296 21.2225C12.8701 21.2225 13.1003 21.1251 13.2678 20.9525L22.7492 11.1747Z"
                  fill="white"
                />
              </svg>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
