import React, { useEffect, useState } from "react";
import CommonHeader from "../../common/CommonHeader";
import GradientButton from "../GradientButton";
import { changePassword, emailotp, emailsubmit, getUser } from "../../../utils/UserController";
import Toast from "../Toast";
import { showLoader } from "../../../utils/Utils";
import loadingRing from "../../../assets/img/spin_ring.png";
import {  animlogo, } from "../../../assets/media";
const BindEmail = () => {
  const [email, setEmail] = useState('')
  const [otp, setOtp] = useState()
  const [loader, setLoader] = useState("Send")

  const [toast, setToast] = useState({
    isVisible: false,
    message: "",
  });

  const showToast = (message) => {
    setToast({ isVisible: true, message });
    setTimeout(() => {
      setToast({ isVisible: false, message: "" });
    }, 3000); // Hide after 3 seconds
  };

  const handleChange = async () => {
    showLoader(true);
    try {
      const res = await emailsubmit({ otp, email });
      showToast(res.data.message);
      showLoader(false);
    } catch (error) {
      // wrong
      showToast(
        `<svg xmlns="http://www.w3.org/2000/svg" width="35px" height="35px" viewBox="0 0 24 24"><path fill="#fff" d="M13 2v14.5h-2V2zm-2 17h2.004v2.004H11z"></path></svg>Invalid data`
      );
      showLoader(false);
     }
  };

  const sendOtp = async () => {
    showLoader(true);
    try {
      const res = await emailotp(email);
      showToast(res.data.message);
      showLoader(false);
    } catch (error) { }
  };
useEffect(()=>{
      const getUserData = async () => {
        showLoader(true);
        try {
          const res = await getUser();
          // if (res.data.status) {
          //   showToast(
          //     `<svg xmlns="http://www.w3.org/2000/svg" width="35px" height="35px" viewBox="0 0 24 24"><path fill="#fff" d="M13 2v14.5h-2V2zm-2 17h2.004v2.004H11z"></path></svg>${res.data.message}`
          //   );
          // }
          showLoader(false);
        } catch (error) {
          showLoader(false);
          console.log(error);
        }
      };
      getUserData();
},[])
 

  return (
    <>
     <div className="loader">
                <div className="loading-box">
                  <img src={animlogo} alt="Loading logo" className="loading-mid" />
                  <img src={loadingRing} alt="Loading ring" className="loading-ring amin-spin" />
                </div>
              </div>
      <CommonHeader
        path="/Setting"
        heading="BindEmail"
        svg={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20px"
            height="20px"
            viewBox="0 0 24 24"
          >
            <path
              fill="var(--lightText)"
              d="m3.55 12l7.35 7.35q.375.375.363.875t-.388.875t-.875.375t-.875-.375l-7.7-7.675q-.3-.3-.45-.675T.825 12t.15-.75t.45-.675l7.7-7.7q.375-.375.888-.363t.887.388t.375.875t-.375.875z"
            />
          </svg>
        }
      />
      <div className="change-container">
        <div className="input-container">
          <div className="label">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25px"
              height="25px"
              viewBox="0 0 36 36"
            >
              <path
                fill="#6485ff"
                d="M32.33 6a2 2 0 0 0-.41 0h-28a2 2 0 0 0-.53.08l14.45 14.39Z"
                className="clr-i-solid clr-i-solid-path-1 color"
              />
              <path
                fill="#6485ff"
                d="m33.81 7.39l-14.56 14.5a2 2 0 0 1-2.82 0L2 7.5a2 2 0 0 0-.07.5v20a2 2 0 0 0 2 2h28a2 2 0 0 0 2-2V8a2 2 0 0 0-.12-.61M5.3 28H3.91v-1.43l7.27-7.21l1.41 1.41Zm26.61 0h-1.4l-7.29-7.23l1.41-1.41l7.27 7.21Z"
                className="clr-i-solid clr-i-solid-path-2 color"
              />
              <path fill="none" d="M0 0h36v36H0z" />
            </svg>
            <span>Email</span>
          </div>
          <div className="input-group">
            <input
              className="d-input password"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Please enter the email"
            />
          </div>
        </div>
        <div className="input-container ">
          <div className="label">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25px"
              height="25px"
              viewBox="0 0 24 24"
            >
              <path
                fill="#6485ff"
                fillRule="evenodd"
                d="M5.25 10.055V8a6.75 6.75 0 0 1 13.5 0v2.055c1.115.083 1.84.293 2.371.824C22 11.757 22 13.172 22 16c0 2.828 0 4.243-.879 5.121C20.243 22 18.828 22 16 22H8c-2.828 0-4.243 0-5.121-.879C2 20.243 2 18.828 2 16c0-2.828 0-4.243.879-5.121c.53-.531 1.256-.741 2.371-.824M6.75 8a5.25 5.25 0 0 1 10.5 0v2.004C16.867 10 16.451 10 16 10H8c-.452 0-.867 0-1.25.004zM14 16a2 2 0 1 1-4 0a2 2 0 0 1 4 0"
                clipRule="evenodd"
              />
            </svg>
            <span>Otp</span>
          </div>
          <div className="input-group position-relative">
            <input
              className="d-input password"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              placeholder="Please enter otp"
            />
          <button className="send-otp" onClick={sendOtp}>{loader}</button>
          </div>
        </div>

        <div className="save-change-btn" onClick={handleChange}>
          <GradientButton
            bg={"var(--main_gradient-color)"}
            text={"Save change"}
            color={"#fff"}
            fw={"bold"}
          />
        </div>


      </div>
      <Toast
        message={toast.message}
        isVisible={toast.isVisible}
        onClose={() => setToast({ isVisible: false, message: "" })}
      />
    </>
  );
};

export default BindEmail;
