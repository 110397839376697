import React, { useEffect, useState } from "react";
import Draggable from "react-draggable";
import $ from "jquery";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import BottomNav from "../common/BottomNav";
import "../../assets/css/home.css";
import lotteryLogo from "../../assets/logo/lottery.png";
import miniGameLogo from "../../assets/logo/minigamelogo.png";
import popularLogo from "../../assets/logo/populargame.png";
import d5Ball from "../../assets/img/d5ball.png";
import trxBall from "../../assets/img/trxball.png";
import user1 from "../../assets/img/user1.png";
import winning1 from "../../assets/img/winning1.png";
import leaderBg from "../../assets/img/leaderbg.png";
import leaderCrown1 from "../../assets/img/leadercrown1.png";
import leaderCrown2 from "../../assets/img/leadercrown2.png";
import leaderCrown3 from "../../assets/img/leadercrown3.png";
import no1 from "../../assets/img/no1.png";
import no2 from "../../assets/img/no2.png";
import no3 from "../../assets/img/no3.png";
import Header from "../common/Header";
import { Link, useNavigate } from "react-router-dom";
import Lottery from "../components/games/Lottery";
import MiniGame from "../components/games/MiniGame";
import Popular from "../components/games/Popular";
import floatingMsg from "../../assets/logo/floatingmsg.png";
import loadingMid from "../../assets/logo/brand_logo.png";
import loadingRing from "../../assets/img/spin_ring.png";

import {
  av1,
  av12,
  av15,
  av17,
  av2,
  av3,
  animlogo,
  av19,
  checkCircle,
  checkImg,
  av5,
  fishing,
  casino,
  sports,
  pvc,
  slots,
  banner1,
  banner2,
  banner3,
  banner4,
  banner5,
  banner6,
  banner7,
  banner8,
  banner9,
  banner10,
  banner11,
  roundLogo,
} from "../../assets/media";
import { formatMoney, handleSpeak, showLoader } from "../../utils/Utils";
import { getUser } from "../../utils/UserController";
import Toast from "../components/Toast";
import Slots from "../components/games/Slots";
import Fishing from "../components/games/Fishing";
import PVC from "../components/games/PVC";
import Casino from "../components/games/Casino";
import Sports from "../components/games/Sports";

const images = [
  banner7,
  banner1,
  banner2,
  banner3,
  banner4,
  banner5,
  banner6,
  banner8,
  banner9,
  banner10,
  banner11];

const Home = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [fsChecked, setFsChecked] = useState(false);
  const [user, setUser] = useState({ first_deposit: 0, money: 0 });
  const navigate = useNavigate();
  const [loopit, setLoopit] = useState(true);
  const [change, setChange] = useState(true);
  const [show, setShow] = useState(true);
  const [text, setText] = useState("All Recharge payment methods on the in3win site are only available in the Recharge menu on");
  const [firstDepositVisible, setFirstDepositVisible] = useState(false);
  const [notificationVisible, setNotificationVisible] = useState(true);
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isInstallable, setIsInstallable] = useState(false);
  const [isDragging, setIsDragging] = useState(false);

  const eventControl = (event, info) => {
    if (event.type === "mousemove" || event.type === "touchmove") {
      setIsDragging(true);
    }

    if (event.type === "mouseup" || event.type === "touchend") {
      setTimeout(() => {
        setIsDragging(false);
      }, 100);
    }
  };
  const handleCloseClick = () => {
    setFirstDepositVisible(false);
    setNotificationVisible(false);
  };

  const handleSpeakText = () => {
    handleSpeak(text);
  };

  useEffect(() => {
    const hasSeenNotification = localStorage.getItem('hasSeenNotification');

    if (!hasSeenNotification) {
      setNotificationVisible(true);
      localStorage.setItem('hasSeenNotification', 'true');
    } else {
      setNotificationVisible(false);
    }
  }, []);

  useEffect(() => {
    const checkModals = () => {
      const noShowModal = $(document).find(".modal").hasClass("show");
      if (!noShowModal) {
        $(document).find(".overlay").addClass("d-none");
      }
    };
    checkModals();
  }, []);

  useEffect(() => {
    const handler = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
      setIsInstallable(true);
    };

    window.addEventListener("beforeinstallprompt", handler);

    return () => {
      window.removeEventListener("beforeinstallprompt", handler);
    };
  }, []);

  const handleInstallClick = async () => {
    const link = document.createElement("a");
    link.href = "/In3Win.apk"; // Replace with your APK's URL
    link.download = "In3Win.apk"; // The name the file will have when downloaded
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const texts = [
    "Due to ongoing issues with the UPI payment system across all banks in India, you may",
    "in3win Official Website To visit our official website, be sure to use the link below",
    "Stay alert for fraudulent sites that look like our in3win official page, refrain from",
    "Also, be aware that when transferring money, you may face delays or failures due to an",
    "The most fun online lottery platform! We are happy to have you join us. As a new member",
    "All Recharge payment methods on the in3win site are only available in the Recharge menu"
  ];

  const changeText = () => {
    setTimeout(() => {
      let index = Math.floor(Math.random() * texts.length);
      setText(texts[index]);
      setChange(!change);
    }, 7000);
  };

  useEffect(() => {
    changeText();
  }, [change]);

  const handleSupport = () => {
    window.location.href = "#/promotion/CustomerSupport";
  };

  const fDeposit = [
    {
      deposit: 100,
      bonus: 28,
    },
    {
      deposit: 200,
      bonus: 48,
    },
    {
      deposit: 500,
      bonus: 108,
    },
    {
      deposit: 1000,
      bonus: 188,
    },
    {
      deposit: 5000,
      bonus: 488,
    },
    {
      deposit: 24000,
      bonus: 1388,
    },
    {
      deposit: 120000,
      bonus: 5888,
    },
    {
      deposit: 240000,
      bonus: 8888,
    },
  ];
  const generateRandomUsername = () => {
    const chars = 'Mem';
    const numbers = 'ASDFGHJKLQWERTYUIO';
    const randomPart =  chars  + 
                      '***' + 
                      Array(3).fill().map(() => numbers.charAt(Math.floor(Math.random() * numbers.length))).join('');
    return randomPart;
  };

  const generateRandomAmount = () => {
    // Generate random amount between 100 and 5000
    const amount = (Math.random() * (5000 - 100) + 100).toFixed(2);
    return `₹${amount}`;
  };

  const lopper = (id) => {
    setTimeout(() => {
      let lastDiv = $("#" + id)
        .find(".winning-item")
        .last();

      lastDiv.animate(
        {
          height: "0px",
          opacity: 0,
          margin: 0,
          padding: "0px 10px 0px 10px",
        },
        200,
        function () {
          let clonedDiv = lastDiv.clone();

          // Update cloned div with random username and amount
          clonedDiv.find(".winning-item-user span").text(generateRandomUsername());
          clonedDiv.find(".winning-item-game-text1").text(`Receive ${generateRandomAmount()}`);

          clonedDiv.css({
            height: "",
            opacity: 0,
            margin: "",
            padding: "10px 10px 10px 10px",
          });
          lastDiv.remove();
          $("#" + id).prepend(clonedDiv);
          clonedDiv.animate(
            {
              opacity: 1,
            },
            400
          );
          setLoopit(!loopit);
        }
      );
    }, 2000);
  };


  useEffect(() => {
    lopper("winners");
  }, [loopit]);

  const [toast, setToast] = useState({
    isVisible: false,
    message: "",
  });

  const showToast = (message) => {
    setToast({ isVisible: true, message });
    setTimeout(() => {
      setToast({ isVisible: false, message: "" });
    }, 5000); // Hide after 3 seconds
  };

  const handleClick = () => {
    navigate("/wallet/Recharge");
  };

  const callGame = () => {
    showToast(
      `<svg xmlns="http://www.w3.org/2000/svg" width="35px" height="35px" viewBox="0 0 24 24"><path fill="#fff" d="M13 2v14.5h-2V2zm-2 17h2.004v2.004H11z"></path></svg>इस गेम को खेलने के लिए आपको कम से कम ₹500.0/- रुपये का रिचार्ज कराना होगा।`
    );
  };

  const tabs = [
    {
      label: "Lottery",
      logo: lotteryLogo,
      content: <Lottery viewall={true} />,
    },
    {
      label: "Mini Games",
      logo: miniGameLogo,
      content: <MiniGame viewall={true} callGame={callGame} />,
    },
    {
      label: "Popular",
      logo: popularLogo,
      content: <Popular viewall={true} callGame={callGame} />,
    },
    {
      label: "Slots",
      logo: slots,
      content: <Slots viewall={true} callGame={callGame} />,
    },
    {
      label: "Fishing",
      logo: fishing,
      content: <Fishing viewall={true} callGame={callGame} />,
    },
    {
      label: "PVC",
      logo: pvc,
      content: <PVC viewall={true} callGame={callGame} />,
    },
    {
      label: "Casino",
      logo: casino,
      content: <Casino viewall={true} callGame={callGame} />,
    },
    {
      label: "Sports",
      logo: sports,
      content: <Sports viewall={true} callGame={callGame} />,
    },
  ];

  const handleHide = () => {
    setShow(false);
  };

  useEffect(() => {
    const getUserData = async () => {
      showLoader(true);
      try {
        const res = await getUser();


        if (res.data.status) {
          setUser(res?.data?.data);
        }
        showLoader(false);


      } catch (error) {
        console.log(error);
        navigate("/login")
      }
    };
    getUserData();
  }, []);

  return (
    <>
      {/* Header */}
      <Header />
      <div className="main-home">
        <div className="slider">
          <Carousel
            autoPlay={true}
            infiniteLoop={true}
            showArrows={false}
            showStatus={false}
            slide={false}
            fade={false}
          >
            {images.map((item, index) => (
              <div key={index}>
                <img src={item} alt={index} />
              </div>
            ))}
          </Carousel>
        </div>
        <div className="newletter d-flex justify-center align-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20px"
            height="20px"
            viewBox="0 0 20 20"
            onClick={handleSpeakText}
          >
            <path
              fill="#6485ff"
              d="M2 7h4l5-4v14l-5-4H2zm12.69-2.46C14.82 4.59 18 5.92 18 10s-3.18 5.41-3.31 5.46a.49.49 0 0 1-.65-.27c-.11-.26.02-.55.27-.65c.11-.05 2.69-1.15 2.69-4.54c0-3.41-2.66-4.53-2.69-4.54a.493.493 0 0 1-.27-.65c.1-.25.39-.38.65-.27M16 10c0 2.57-2.23 3.43-2.32 3.47q-.09.03-.18.03c-.2 0-.39-.12-.47-.32c-.1-.26.04-.55.29-.65c.07-.02 1.68-.67 1.68-2.53s-1.61-2.51-1.68-2.53a.52.52 0 0 1-.29-.65c.1-.25.39-.39.65-.29c.09.04 2.32.9 2.32 3.47"
            />
          </svg>
          <div className="text">{text}</div>
          <div className="d-flex justify-center align-center btn">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10px"
              height="10px"
              viewBox="0 0 48 48"
            >
              <path
                fill="#fff"
                stroke="#fff"
                strokeLinejoin="round"
                strokeWidth="4"
                d="M24 44c8.235 0 15-6.526 15-14.902c0-2.056-.105-4.26-1.245-7.686c-1.14-3.426-1.369-3.868-2.574-5.984c-.515 4.317-3.27 6.117-3.97 6.655c0-.56-1.666-6.747-4.193-10.45C24.537 8 21.163 5.617 19.185 4c0 3.07-.863 7.634-2.1 9.96c-1.236 2.325-1.468 2.41-3.013 4.14c-1.544 1.73-2.253 2.265-3.545 4.365C9.236 24.565 9 27.362 9 29.418C9 37.794 15.765 44 24 44Z"
              />
            </svg>
            <Link to="/main/Notification" className="flex items-center">
              <span>Detail</span>
            </Link>
          </div>
        </div>
        <div className="tabs">
          <div className="tab-buttons">
            {tabs.map((tab, index) => (
              <div
                key={index}
                className={`tab-button ${activeTab === index ? "active" : ""}`}
                onClick={() => setActiveTab(index)}
              // style={{ backgroundImage: `url(${tabBg2}` }}
              >
                <img src={tab.logo} alt="" />
                {tab.label}
              </div>
            ))}
          </div>
          <div className="tab-content">{tabs[activeTab].content}</div>
        </div>
        <div className="winning-info mt-1">
          <div className="winning-heading  mb-2">
            <span>Winning Information</span>
          </div>
          <div className="winning-items" id="winners">
            <div className="winning-item">
              <div className="winning-item-user d-flex align-center ">
                <img src={av2} alt="" />
                <span>Mem***OID</span>
              </div>
              <div className="winning-item-game d-flex align-center justify-center">
                <div
                  className="winning-game-image"
                  style={{ backgroundImage: `url(${d5Ball})` }}
                ></div>
                <div className="winning-item-game-text">
                  <span className="winning-item-game-text1">
                    Receive ₹1,384.00
                  </span>
                  <span className="winning-item-game-text2">
                    Winning amount
                  </span>
                </div>
              </div>
            </div>
            <div className="winning-item">
              <div className="winning-item-user d-flex align-center ">
                <img src={av5} alt="" />
                <span>Mem***IUS</span>
              </div>
              <div className="winning-item-game d-flex align-center justify-center">
                <div
                  className="winning-game-image"
                  style={{ backgroundImage: `url(${winning1})` }}
                ></div>
                <div className="winning-item-game-text">
                  <span className="winning-item-game-text1">
                    Receive ₹5,644.00
                  </span>
                  <span className="winning-item-game-text2">
                    Winning amount
                  </span>
                </div>
              </div>
            </div>
            <div className="winning-item">
              <div className="winning-item-user d-flex align-center ">
                <img src={av1} alt="" />
                <span>MEM***CIN</span>
              </div>
              <div className="winning-item-game d-flex align-center justify-center">
                <div
                  className="winning-game-image"
                  style={{ backgroundImage: `url(${winning1})` }}
                ></div>
                <div className="winning-item-game-text">
                  <span className="winning-item-game-text1">
                    Receive ₹2,114.00
                  </span>
                  <span className="winning-item-game-text2">
                    Winning amount
                  </span>
                </div>
              </div>
            </div>
            <div className="winning-item">
              <div className="winning-item-user d-flex align-center ">
                <img src={av2} alt="" />
                <span>Mem***OID</span>
              </div>
              <div className="winning-item-game d-flex align-center justify-center">
                <div
                  className="winning-game-image"
                  style={{ backgroundImage: `url(${trxBall})` }}
                ></div>
                <div className="winning-item-game-text">
                  <span className="winning-item-game-text1">
                    Receive ₹1,384.00
                  </span>
                  <span className="winning-item-game-text2">
                    Winning amount
                  </span>
                </div>
              </div>
            </div>
            <div className="winning-item">
              <div className="winning-item-user d-flex align-center ">
                <img src={av3} alt="" />
                <span>Mem***VYU</span>
              </div>
              <div className="winning-item-game d-flex align-center justify-center">
                <div
                  className="winning-game-image"
                  style={{ backgroundImage: `url(${winning1})` }}
                ></div>
                <div className="winning-item-game-text">
                  <span className="winning-item-game-text1">
                    Receive ₹8,800.00
                  </span>
                  <span className="winning-item-game-text2">
                    Winning amount
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="earning-chart mt-1">
          <div className="earning-heading  mb-1">
            <span>Today's earnings chart</span>
          </div>
          <div className="leader-board">
            <div className="leader-bg-image">
              <img src={leaderBg} alt="" />
            </div>
            <div className="leader-container">
              <div className="leader-container2 d-flex justify-between">
                <div className="leader">
                  <div className="leader-image-container">
                    <img src={av12} alt="" className="leader-user-image" />
                    <div className="leader-user-container">
                      <img src={leaderCrown2} alt="" className="crown" />
                      <img src={no2} alt="" className="badge" />
                    </div>
                  </div>
                  <span className="leader-userid">Mem***MDN</span>
                  <span className="leader-money">₹15,30,092.00</span>
                </div>
                <div className="leader x">
                  <div className="leader-image-container">
                    <img src={av15} alt="" className="leader-user-image" />
                    <div className="leader-user-container">
                      <img src={leaderCrown1} alt="" className="crown" />
                      <img src={no1} alt="" className="badge" />
                    </div>
                  </div>
                  <span className="leader-userid">Mem***SDK</span>
                  <span className="leader-money">₹22,50,680.00</span>
                </div>
                <div className="leader">
                  <div className="leader-image-container">
                    <img src={av17} alt="" className="leader-user-image" />
                    <div className="leader-user-container">
                      <img src={leaderCrown3} alt="" className="crown" />
                      <img src={no3} alt="" className="badge" />
                    </div>
                  </div>
                  <span className="leader-userid">Mem***LAK</span>
                  <span className="leader-money">₹5,04,980.00</span>
                </div>
              </div>
            </div>
          </div>
          <div className="winning-item x">
            <div className="winning-item-user x d-flex align-center justify-center">
              <span className="pos">4</span>
              <img src={user1} alt="" />
              <span>Mem***HTO</span>
            </div>
            <div className="leader-money x">₹2,00,070.00</div>
          </div>
          <div className="winning-item x">
            <div className="winning-item-user x d-flex align-center justify-center">
              <span className="pos">5</span>
              <img src={av19} alt="" />
              <span>Mem***AMA</span>
            </div>
            <div className="leader-money x">₹85,000.00</div>
          </div>
        </div>
        <div style={{ height: "135px" }}></div>
      </div>
      {/* Bottom Navigation */}
      <BottomNav />
      <div
        className={`model ${notificationVisible ? "show" : ""}`}
        id="notification"
      >
        <div
          className="model-container"
          style={{ width: "75%", height: "60%" }}
        >
          <div className="model-policy-header">
            <span>Notification</span>
          </div>
          <div className="model-policy-content">
            <p>
              <b>
                <span style={{ fontFamily: "Microsoft YaHei" }}>Avoid fraudulents</span><br />
                <span style={{ fontFamily: "Microsoft YaHei" }}>
                  · Refrain from transferring money to anyone, including agents.
                </span><br />
                <span style={{ fontFamily: "Microsoft YaHei" }}>
                  · Be cautious of imitation websites that look like our genuine site.
                </span><br />
                <span style={{ fontFamily: "Microsoft YaHei" }}>
                  · Refrain from providing receipt or UTR information to anyone, including agents.
                </span>
              </b>
              <span style={{ fontWeight: "bold", fontFamily: "Arial Black" }}></span>
            </p>
            <p><br />
              <b>Members please remember <a href="http://in3win.net" target="_blank" rel="noopener noreferrer">in3win.net</a> to check whether our in3win website is available.</b>
            </p>
            <p>
              <b>
                <span style={{ fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif" }}>
                  Be part of our in3win Official Channel to get updated with spectacular Events, good news and various announcements. Thank you!!
                </span>
              </b>
            </p>
            <p>
              <b>
                <span style={{ fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif" }}>in3win Official Channel：</span><br />
              </b>
            </p>
            <p>
              <a href="https://t.me/in3win" target="_blank" rel="noopener noreferrer">
                <b>https://t.me/in3win</b>
              </a>
            </p>
            <p>
              <b>
                <span style={{ fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif" }}>in3win Prediction Official Channel</span><br />
              </b>
            </p>
            <p>
              <b>
                <a href="https://t.me/in3winofficial" target="_blank" rel="noopener noreferrer">https://t.me/in3winofficial</a><br />
              </b>
            </p>
            <p>
              <b>
                Welcome to in3win,<br />
                <span style={{ fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif" }}>
                  🔥🔥 We have many bonuses for you to claim 🔥🔥
                </span>
              </b>
            </p>
            <p>
              <b>
                <span style={{ fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif" }}>
                  1. First Deposit Bonus: after registering and binding your bank account to claim the bonus<br />
                  2. Attendance Bonus: deposit for 7 days straight to claim the bonus 📋📋<br />
                  3. Gifts Code: use the code the agent gives to receive a bonus 🎁🎁<br />
                  4. Super Jackpot: play slot games, and when you have a big win, you can get the bonus 🎰🎰<br />
                  5. Aviator High Betting Award: play the aviator game, and when you reach the multiplier, you can claim the bonus<br />
                  6. VIP Bonus: this bonus you can claim monthly and for every VIP level you increase<br />
                  7. YouTube Creative Video: make a video content about in3win and claim the prize that awaits you
                </span>
              </b>
            </p>
            <p>
              <span style={{ fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif" }}>
                <b>Join us and become part of our agent program to get amazing benefits. The best aspect of joining our agent program is that the more you invite and the higher the level of your downlines, the greater the bonuses you may receive, resulting in more downlines.</b>
              </span>
            </p>

          </div>
          <div className="model-policy-footer">
            <div
              className="i-know-btn"
              onClick={() => {
                setNotificationVisible(false);
                setTimeout(() => {
                  setFirstDepositVisible(true);
                }, 1000);
              }}
            >
              Confirm
            </div>
          </div>
        </div>
      </div>
      <div
        className={`model ${firstDepositVisible ? "show" : ""}`}
        id="firstDeposit"
      >
        <div
          className="model-container"
          style={{ width: "80%", height: "70%", position: "relative" }}
        >
          <div
            className="model-policy-header"
            style={{ background: "#23367d", height: "80px" }}
          >
            <div
              className="d-flex flex-col align-center"
              style={{ gap: "5px" }}
            >
              <span>Extra first deposit bonus</span>
              <span style={{ fontSize: ".7rem" }}>
                Each account can only receive rewards once
              </span>
            </div>
          </div>
          <div className="model-policy-content" style={{ padding: "10px" }}>
            {fDeposit.map((v, i) => (
              <div className="first_list-item" key={i}>
                <div className="fs-head">
                  <div className="fs-title">
                    <span>First deposit </span>
                    <span style={{ color: "#ff9832" }}>{v.deposit}</span>
                  </div>
                  <span style={{ color: "#ff9832" }}>
                    + {formatMoney(v.bonus)}
                  </span>
                </div>
                <div className="fs-des">
                  Deposit {v.deposit} for the first time and you will receive{" "}
                  {v.bonus} bonus
                </div>
                <div className="d-flex align-center justify-between">
                  <div className="fs-comp">
                    <span>0</span>/<span>{v.deposit}</span>
                  </div>
                  <div className={`fs-deposit-btn`} onClick={handleClick}>
                    Deposit
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div
            className="model-policy-footer"
            style={{ justifyContent: "space-around" }}
          >
            <img
              src={fsChecked ? checkImg : checkCircle}
              alt=""
              style={{ width: "22px", filter: "hue-rotate(313deg)" }}
              onClick={() => {
                setFsChecked(!fsChecked);
              }}
            />
            <span style={{ color: "#768096", fontSize: ".8rem" }}>
              No more reminders today
            </span>
            <Link to={"/activity/FirstDeposit"}>
              <div
                className="i-know-btn"
                style={{
                  width: "max-content",
                  padding: "6px 20px",
                  color: "#fff",
                }}
                onClick={handleCloseClick}
              >
                Activity
              </div>
            </Link>
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="50px"
            height="50px"
            viewBox="0 0 24 24"
            style={{ position: "absolute", bottom: "-50px" }}
            onClick={handleCloseClick}
          >
            <path
              fill="#fff"
              d="m8.4 16.308l3.6-3.6l3.6 3.6l.708-.708l-3.6-3.6l3.6-3.6l-.708-.708l-3.6 3.6l-3.6-3.6l-.708.708l3.6 3.6l-3.6 3.6zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709M12 20q3.35 0 5.675-2.325T20 12t-2.325-5.675T12 4T6.325 6.325T4 12t2.325 5.675T12 20m0-8"
            ></path>
          </svg>
        </div>
      </div>

      <>
        {show ? (
          <div className="download-btn">
            <img src={roundLogo} alt="" onClick={handleInstallClick} />
            <span onClick={handleInstallClick}>Add to Desktop</span>
            <svg
              className="line"
              width="1"
              height="30"
              viewBox="0 0 1 60"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line x1="0.5" x2="0.5" y2="60" stroke="white"></line>
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30px"
              height="30px"
              viewBox="0 0 21 21"
              onClick={handleHide}
            >
              <g
                fill="none"
                fillRule="evenodd"
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                transform="translate(2 2)"
              >
                <circle cx={8.5} cy={8.5} r={8}></circle>
                <path d="m5.5 5.5l6 6m0-6l-6 6"></path>
              </g>
            </svg>
          </div>
        ) : (
          ""
        )}
      </>
      <div className="loader">
        <div className="loading-box">
          <img src={animlogo} alt="" className="loading-mid" />
          <img src={loadingRing} alt="" className="loading-ring amin-spin" />
        </div>
      </div>
      <Toast
        message={toast.message}
        isVisible={toast.isVisible}
        onClose={() => setToast({ isVisible: false, message: "" })}
      />
    </>
  );
};

export default Home;
