import React, { useState, useEffect } from "react";
import $ from "jquery";
import { Link, useLocation, useNavigate } from "react-router-dom";
import LoginHeader from "../common/LoginHeader";
import { checkImg, checkCircle, eyeClose, eyeOpen ,  animlogo,} from "../../assets/media";
import { getOtp, getUser, Register } from "../../utils/UserController";
import Toast from "../components/Toast";
import { showLoader } from "../../utils/Utils";
import Cookies from 'js-cookie';
import En from "../../assets/icons/en.png"
import loadingRing from "../../assets/img/spin_ring.png";

const RegisterPage = () => {
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [inviteCode, setInviteCode] = useState("7050992100000");
  const [otp, setOtp] = useState("0000");
  const [registerTab, setRegisterTab] = useState("phone");
  const [viewPhonePass, setViewPhonePass] = useState(false);
  const [viewEmailPass, setViewEmailPass] = useState(false);
  const [viewPhoneCnfPass, setViewPhoneCnfPass] = useState(false);
  const [viewEmailCnfPass, setViewEmailCnfPass] = useState(false);
  const [agree, setAgree] = useState(false);
  const [sentOtp, setSentOtp] = useState(false);
  const [pcode, setPcode] = useState("+91");
  const [cphoneview, setCphoneview] = useState(false);
  const [openPopup, setOpenPopup] = useState(false)

  const navigate = useNavigate();
  const [toast, setToast] = useState({
    isVisible: false,
    message: "",
  });
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const paramValue = queryParams.get("InvitationCode"); // E

  const showToast = (message) => {
    setToast({ isVisible: true, message });
    setTimeout(() => {
      setToast({ isVisible: false, message: "" });
    }, 3000);
  };

  const handleRegister = async () => {
    showLoader(true);
    if (registerTab === "email") {
      showToast(
        `<svg xmlns="http://www.w3.org/2000/svg" width="35px" height="35px" viewBox="0 0 24 24"><path fill="#fff" d="M13 2v14.5h-2V2zm-2 17h2.004v2.004H11z"></path></svg>Please register with your phone number`
      );
      showLoader(false);
      return false;
    }
    if (!agree) {
      showToast(
        `<svg xmlns="http://www.w3.org/2000/svg" width="35px" height="35px" viewBox="0 0 24 24"><path fill="#fff" d="M13 2v14.5h-2V2zm-2 17h2.004v2.004H11z"></path></svg>Please check I agree to the pre-sale rules`
      );
      showLoader(false);
      return;
    }

    if (phone === "" || phone.length < 10) {
      showToast(
        `<svg xmlns="http://www.w3.org/2000/svg" width="35px" height="35px" viewBox="0 0 24 24"><path fill="#fff" d="M13 2v14.5h-2V2zm-2 17h2.004v2.004H11z"></path></svg>Invalid phone`
      );
      showLoader(false);
      return false;
    }
    if (sentOtp) {
      showToast(
        `<svg xmlns="http://www.w3.org/2000/svg" width="35px" height="35px" viewBox="0 0 24 24"><path fill="#fff" d="M13 2v14.5h-2V2zm-2 17h2.004v2.004H11z"></path></svg>Send otp first!`
      );
      showLoader(false);
      return false;
    }
    if (otp === "" || otp.length < 4) {
      showToast(
        `<svg xmlns="http://www.w3.org/2000/svg" width="35px" height="35px" viewBox="0 0 24 24"><path fill="#fff" d="M13 2v14.5h-2V2zm-2 17h2.004v2.004H11z"></path></svg>Invalid otp`
      );
      showLoader(false);
      return false;
    }

    if (password === "" || password.length < 6) {
      showToast(
        `<svg xmlns="http://www.w3.org/2000/svg" width="35px" height="35px" viewBox="0 0 24 24"><path fill="#fff" d="M13 2v14.5h-2V2zm-2 17h2.004v2.004H11z"></path></svg>Enter password`
      );
      showLoader(false);
      return false;
    }

    if (password !== confirmPassword) {
      showToast(
        `<svg xmlns="http://www.w3.org/2000/svg" width="35px" height="35px" viewBox="0 0 24 24"><path fill="#fff" d="M13 2v14.5h-2V2zm-2 17h2.004v2.004H11z"></path></svg>Enter confirm password doesn't match.`
      );
      showLoader(false);
      return false;
    }
    try {
      const res = await Register(phone, password, confirmPassword, inviteCode);
      if (res.data.status) {
        showToast(
          `<svg xmlns="http://www.w3.org/2000/svg" width="35px" height="35px" viewBox="0 0 24 24"><path fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth={3} d="M5 14.5s1.5 0 3.5 3.5c0 0 5.559-9.167 10.5-11" color="#fff"></path></svg>${res.data.message}`
        );
        setTimeout(() => {
          const token = res.data.data.token;
          localStorage.setItem("token", token);
          localStorage.setItem("uid", res.data.data.user.uid);
          localStorage.setItem("bcRememberedPhone", phone);
          localStorage.setItem("bcRememberedPassword", password);
          Cookies.set('auth', token, {
            secure: true,         // Ensures the cookie is sent over HTTPS only
            sameSite: 'None',     // Required for cross-domain cookies
            domain: '.chennaiclub.org' // Set to the primary domain, starting with a dot for subdomain access
          });
          navigate("/");
        }, 1000);
      } else {
        showToast(
          `<svg xmlns="http://www.w3.org/2000/svg" width="35px" height="35px" viewBox="0 0 24 24"><path fill="#fff" d="M13 2v14.5h-2V2zm-2 17h2.004v2.004H11z"></path></svg>${res.data.message}`
        );
      }
      showLoader(false);
    } catch (error) {
      console.log(error);
      showLoader(false);
    }
  };

  useEffect(() => {
    // showLoader(true);
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        handleRegister();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleAgree = () => {
    setAgree(!agree);
  };

  const handlePcode = () => {
    setCphoneview(!cphoneview);
  };

  const getOtpData = async () => {

    $(".loader").removeClass("d-none");
    if (phone === "" || phone.length < 10) {
      showToast(
        `<svg xmlns="http://www.w3.org/2000/svg" width="35px" height="35px" viewBox="0 0 24 24"><path fill="#fff" d="M13 2v14.5h-2V2zm-2 17h2.004v2.004H11z"></path></svg>Invalid phone`
      );
      return false;
    }
    try {
      const res = await getOtp(phone);
      if (res.data.data.status) {
        setSentOtp(true);
        showToast(
          `<svg width="25px" height="25px" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="iconify iconify--emojione-monotone" preserveAspectRatio="xMidYMid meet" fill="#fff" stroke="#fff"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M56 2L18.8 42.909L8 34.729H2L18.8 62L62 2z" fill="#fff"></path></g></svg>${res.data.message}`
        );
      } else {
        showToast(
          `<svg xmlns="http://www.w3.org/2000/svg" width="35px" height="35px" viewBox="0 0 24 24"><path fill="#fff" d="M13 2v14.5h-2V2zm-2 17h2.004v2.004H11z"></path></svg>${res.data.message}`
        );
      }
      $(".loader").addClass("d-none");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const rememberedPhone = localStorage.getItem("bcRememberedPhone");
    const rememberedPassword = localStorage.getItem("bcRememberedPassword");

    if (rememberedPhone && rememberedPassword) {
      setPhone(rememberedPhone);
      setPassword(rememberedPassword);
    }

    setInviteCode(paramValue);

        const getUserData = async () => {
          showLoader(true);
          try {
            const res = await getUser();
            if (res.data.status) {
              showToast(
                `<svg xmlns="http://www.w3.org/2000/svg" width="35px" height="35px" viewBox="0 0 24 24"><path fill="#fff" d="M13 2v14.5h-2V2zm-2 17h2.004v2.004H11z"></path></svg>${res.data.message}`
              );
            }
            showLoader(false);
          } catch (error) {
            showLoader(false);
            console.log(error);
          }
        };
        getUserData();
  }, []);

  return (
    <>
     <div className="loader">
                <div className="loading-box">
                  <img src={animlogo} alt="Loading logo" className="loading-mid" />
                  <img src={loadingRing} alt="Loading ring" className="loading-ring amin-spin" />
                </div>
              </div>
      <div className="container">
        <LoginHeader path="/" heading="Login" setOpenPopup={setOpenPopup} />
        <div className="info">
          <span className="info-heading">Register</span>
          <span className="info-text">
            Please register by phone number or email
          </span>
        </div>
        <div className="tab">
          <div
            className={`tab1  ${registerTab === "phone" ? "active" : ""}`}
            onClick={() => setRegisterTab("phone")} style={{width:"87%"}}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25px"
              height="25px"
              viewBox="0 0 48 48"
            >
              <defs>
                <mask id="ipSPhone0">
                  <g fill="none" strokeWidth="4">
                    <path
                      fill="#fff"
                      stroke="#fff"
                      strokeLinejoin="round"
                      d="M8 30h32v12a2 2 0 0 1-2 2H10a2 2 0 0 1-2-2z"
                    />
                    <path
                      stroke="#fff"
                      strokeLinejoin="round"
                      d="M40 30V6a2 2 0 0 0-2-2H10a2 2 0 0 0-2 2v24"
                    />
                    <path stroke="#000" strokeLinecap="round" d="M22 37h4" />
                  </g>
                </mask>
              </defs>
              <path
                className="color"
                fill="#768096"
                d="M0 0h48v48H0z"
                mask="url(#ipSPhone0)"
              />
            </svg>
            <span className="phone-reg-text">Register your phone</span>
          </div>
         
        </div>
       
          <div className="login-container">
            <div className="input-container">
              <div className="label">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25px"
                  height="25px"
                  viewBox="0 0 48 48"
                >
                  <defs>
                    <mask id="ipSPhone0">
                      <g fill="none" strokeWidth="4">
                        <path
                          fill="#fff"
                          stroke="#fff"
                          strokeLinejoin="round"
                          d="M8 30h32v12a2 2 0 0 1-2 2H10a2 2 0 0 1-2-2z"
                        />
                        <path
                          stroke="#fff"
                          strokeLinejoin="round"
                          d="M40 30V6a2 2 0 0 0-2-2H10a2 2 0 0 0-2 2v24"
                        />
                        <path
                          stroke="#000"
                          strokeLinecap="round"
                          d="M22 37h4"
                        />
                      </g>
                    </mask>
                  </defs>
                  <path
                    className="color"
                    fill="#6485ff"
                    d="M0 0h48v48H0z"
                    mask="url(#ipSPhone0)"
                  />
                </svg>
                <span>Phone number</span>
              </div>
              <div className="input-group">
                <div
                  className="input"
                  style={{ position: "relative" }}
                  onClick={handlePcode}
                >
                  {pcode}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25px"
                    height="25px"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="none"
                      stroke="#768096"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m7 10l5 5m0 0l5-5"
                    />
                  </svg>
                  <div className={`pcode-dropdown ${cphoneview ? "show" : ""}`}>
                    <ul>
                      <li>+91 - India ( भारत )</li>
                    </ul>
                  </div>
                </div>
                <input
                  type="number"
                  className="d-input"
                  value={phone}
                  placeholder="Enter phone number"
                  onChange={(e) => setPhone(e.target.value)}
                  style={{ marginLeft: "10px" }}
                />
              </div>
            </div>
            
            <div className="input-container">
              <div className="label">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25px"
                  height="25px"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="#6485ff"
                    fillRule="evenodd"
                    d="M5.25 10.055V8a6.75 6.75 0 0 1 13.5 0v2.055c1.115.083 1.84.293 2.371.824C22 11.757 22 13.172 22 16c0 2.828 0 4.243-.879 5.121C20.243 22 18.828 22 16 22H8c-2.828 0-4.243 0-5.121-.879C2 20.243 2 18.828 2 16c0-2.828 0-4.243.879-5.121c.53-.531 1.256-.741 2.371-.824M6.75 8a5.25 5.25 0 0 1 10.5 0v2.004C16.867 10 16.451 10 16 10H8c-.452 0-.867 0-1.25.004zM14 16a2 2 0 1 1-4 0a2 2 0 0 1 4 0"
                    clipRule="evenodd"
                  />
                </svg>
                <span>Set password</span>
              </div>
              <div className="input-group">
                <input
                  value={password}
                  className="d-input password"
                  placeholder="Enter your password"
                  onChange={(e) => setPassword(e.target.value)}
                  type={viewPhonePass ? "text" : "password"}
                />
                <img
                  src={`${viewPhonePass ? eyeOpen : eyeClose}`}
                  alt=""
                  onClick={() => setViewPhonePass(!viewPhonePass)}
                />
              </div>
            </div>
            <div className="input-container">
              <div className="label">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25px"
                  height="25px"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="#6485ff"
                    fillRule="evenodd"
                    d="M5.25 10.055V8a6.75 6.75 0 0 1 13.5 0v2.055c1.115.083 1.84.293 2.371.824C22 11.757 22 13.172 22 16c0 2.828 0 4.243-.879 5.121C20.243 22 18.828 22 16 22H8c-2.828 0-4.243 0-5.121-.879C2 20.243 2 18.828 2 16c0-2.828 0-4.243.879-5.121c.53-.531 1.256-.741 2.371-.824M6.75 8a5.25 5.25 0 0 1 10.5 0v2.004C16.867 10 16.451 10 16 10H8c-.452 0-.867 0-1.25.004zM14 16a2 2 0 1 1-4 0a2 2 0 0 1 4 0"
                    clipRule="evenodd"
                  />
                </svg>
                <span>Confirm password</span>
              </div>
              <div className="input-group">
                <input
                  className="d-input password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  type={viewPhoneCnfPass ? "text" : "password"}
                  placeholder="Enter your confirm password"
                />
                <img
                  src={`${viewPhoneCnfPass ? eyeOpen : eyeClose}`}
                  alt=""
                  onClick={() => setViewPhoneCnfPass(!viewPhoneCnfPass)}
                />
              </div>
            </div>
            <div className="input-container">
              <div className="label">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25px"
                  height="25px"
                  viewBox="0 0 24 24"
                >
                  <g fill="none">
                    <path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035q-.016-.005-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093q.019.005.029-.008l.004-.014l-.034-.614q-.005-.019-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" />
                    <path
                      fill="#6485ff"
                      d="M17 3a3 3 0 0 1 2.995 2.824L20 6v4.35l.594-.264c.614-.273 1.322.15 1.4.798L22 11v8a2 2 0 0 1-1.85 1.995L20 21H4a2 2 0 0 1-1.995-1.85L2 19v-8c0-.672.675-1.147 1.297-.955l.11.041l.593.264V6a3 3 0 0 1 2.824-2.995L7 3zm0 2H7a1 1 0 0 0-1 1v5.239l6 2.667l6-2.667V6a1 1 0 0 0-1-1m-5 3a1 1 0 0 1 .117 1.993L12 10h-2a1 1 0 0 1-.117-1.993L10 8z"
                    />
                  </g>
                </svg>
                <span>Invite code</span>
              </div>
              <div className="input-group">
                <input
                  type="number"
                  className="d-input"
                  value={paramValue ? paramValue : inviteCode}
                  placeholder="Enter invite code"
                  onChange={(e) => setInviteCode(e.target.value)}
                />
              </div>
            </div>

            <div className="input-container">
              <div className="label">
                <div
                  className="d-flex align-center"
                  style={{ gap: "10px" }}
                  onClick={handleAgree}
                >
                  <img
                    src={agree ? checkImg : checkCircle}
                    alt=""
                    className="check-img"
                  />
                  <span className="rp">I have read and agree</span>
                </div>
                <Link to="/account/About">
                  <span style={{ color: "#f95959" }} className="rp">
                    【Privacy Agreement】
                  </span>
                </Link>
              </div>
            </div>

            <div className="button-container">
              <div className="btn active" onClick={handleRegister}>
                Register
              </div>
              <div className="btn">
                <span
                  style={{
                    fontSize: ".85rem",
                    color: "#768096",
                    marginRight: "10px",
                    fontWeight: "400",
                  }}
                >
                  I have an account{" "}
                </span>
                <Link to="/login">Login</Link>
              </div>
            </div>
          </div>
       
      </div>
      <Toast
        message={toast.message}
        isVisible={toast.isVisible}
        onClose={() => setToast({ isVisible: false, message: "" })}
      />

      <div className={`${openPopup ? "overlay" : "d-none"}`} onClick={() => setOpenPopup(false)}>

      </div>
      <div className={`off-canvas ${openPopup ? "open" : ""}`} onClick={() => setOpenPopup(false)}>
        <div className="off-canvas-container">
          <div className="off-canvas-content withdrawal-boxs">
            <div className='p-4 d-flex items-center justify-between' style={{ padding: " 10px" }}>
              <div className="d-flex items-center">
                <img src={En} alt="" style={{ width: "30px" }} />
                <span className="ms-1 " style={{ fontWeight: "500", fontSize: "16px" }}>EN</span>
              </div>
              <img
                src={checkImg}
                alt=""
                className="check-img"
              />

            </div>
            <p style={{ padding: "10px" }}>
              <hr style={{ borderColor: "#fff" }} />
            </p>
          </div>

        </div>
      </div>
    </>
  );
};

export default RegisterPage;
