import { io } from "socket.io-client";
// const URL= "http://localhost:4066"
// Change to your server URL if different
const URL = "https://api.chennaiclub.org"; // Change to your server URL if different
const socket = io(URL);
// Live stream
export const webhost = URL
export default socket;


