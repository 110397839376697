import React from "react";
import "../../assets/css/main.css"; // Import CSS for styling

const Toast2 = ({ message, isVisible, sm }) => {
  if (!isVisible) return null;

  return (
    <div className={`toast-container ${sm ? "sm" : ""}`}>
      <div
        className={`toast-message2 ${sm ? "sm" : ""}`}
        dangerouslySetInnerHTML={{ __html: message }}
      ></div>
    </div>
  );
};

export default Toast2;
