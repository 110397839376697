import React, { useState, useEffect, useRef } from "react";
import $ from "jquery";
import socket, { webhost } from "../../../../socket";
import { formatDate, formatMoney } from "../../../../utils/Utils";
import {
  lowAudio,
  highAudio,
  notFoundImg,
  checkImg,
  checkCircle,
  ludo1,
  ludo2,
  ludo3,
  ludo4,
  ludo5,
  ludo6,
  noData,
} from "../../../../assets/media";
import {
  getUser,
  get5dBetHistory,
  getUserWinStatus,
} from "../../../../utils/UserController";
import { getD5List } from "../../../../utils/DataController";
import { betD5 } from "../../../../utils/BetController";
import "../../../../assets/css/d5.css";
import "../../../../assets/css/wingo.css";
import { io } from "socket.io-client";
import Toast from "../../Toast";
import { PiCopySimpleBold } from "react-icons/pi";
import Toast2 from "../../Toast2";

const D51 = ({ xmoney, user_id, updateUser, showResult }) => {
  const [money, setMoney] = useState(0);
  const [activeTab, setActiveTab] = useState("Game History");
  const [betActiveTab, setBetActiveTab] = useState("A");
  const [bg, setBg] = useState("");
  const [agree, setAgree] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [mltValue, setMltValue] = useState(1);
  const [betAmount, setBetAmount] = useState(1);
  const [balance, setBalance] = useState(1);
  const [wingoList, setWingoList] = useState([]);
  const [nextPreiod, setNextPreiod] = useState(20240000000);
  const [triggerEffect, setTriggerEffect] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [timer, setTimer] = useState(0);
  const [qty2, setQty2] = useState(1);
  const [bet, setBet] = useState(null);
  const [minutetime2, setMinutetime2] = useState(0);
  const [secondtime1, setSecondtime1] = useState(0);
  const [secondtime2, setSecondtime2] = useState(0);
  const [selectTab, setSelectTab] = useState(null);
  const [selectBet, setSelectBet] = useState(null);

  const [animating, setAnimating] = useState(false);
  const [numbers, setNumbers] = useState([2, 7, 1, 4, 3]);
  const [activeBoxbet, setActiveBoxbet] = useState("");
  const [activeNumbers, setActiveNumbers] = useState([]);
  const [click, setClick] = useState(false);

  const low_audio = new Audio(lowAudio);
  const high_audio = new Audio(highAudio);
  let removeModel;

 const fetchWingoList = async (page,pageto) => {
    try {
      const response = await getD5List(10, page, pageto);
      if (response.data.status) {
        setWingoList(response?.data?.data?.gameslist);
        if (page === 1) {
          const numbers = response.data.data.gameslist[0].result.split("")
        
          setNextPreiod(response.data.period);
          setNumbers(numbers);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

 const fetchUserWinStatus = async () => {
   try {
     const response = await getUserWinStatus("d5", "10min");
     const status = response.data.data.win_status;
     const betData = response.data.data.bet_data;
     const data = {
       status: status,
       type: "1",
       period_id: response.data.data.period_id,
       amount: response.data.data.amount,
       win_amount: response.data.data.result_amount,
       color: betData.ludo_result,
       size: betData.size_result,
       number: betData.num_result,
     };

     showResult(data);
   } catch (err) {
     console.log(err);
   }
 };

  const refreshUserData = async () => {
    const userData = await getUser();
    if (userData.status === 200) {
      updateUser(userData);
      if (userData.data.data) {
        setMoney(userData.data.data.money);
      }
    }
  };

  const randomize = () => {
    setAnimating(true);
    const timeout = setTimeout(() => {
      const fetchData = async () => {
        const response = await getD5List(10, 1, 10);
        if (response.data.status) {
          const numbers = response.data.data.gameslist[0].result.split("");
          setNumbers(numbers);
        }
      };
      fetchData();
      setTimeout(() => {
        setAnimating(false);
      }, 4000);
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  };

  const toggleOffCanvas = (open) => {
    setIsOpen(open);
    setBet({ betActiveTab, activeBoxbet, activeNumbers, qty: mltValue + qty2 });
    if(betActiveTab === "Sum"){
      setSelectTab("total");
    }else{
      setSelectTab(betActiveTab.toLowerCase());
    }
    const betMapping = {
      even: "c",
      big: "b",
      odd: "l",
      small: "s"
  };
if(activeBoxbet){
  setSelectBet(betMapping[activeBoxbet]);
}else{
  setSelectBet(Number(activeNumbers.join("")));
}

    if (!open) {
      refreshUserData();
      $(".overlay").addClass("d-none");
      $(".container").css("overflow", "");
      setActiveBoxbet("");
      setActiveNumbers([]);
    } else {
      $(".overlay").removeClass("d-none");
      $(".container").css("overflow", "hidden");
      $(".wingo-model-content1").prop("class", "wingo-model-content1");
    }
  };

  const tabs = [
    {
      label: "Game History",
      content: <GameHistory triggerEffect={triggerEffect} />,
    },
    {
      label: "Chart",
      content: <Chart triggerEffect={triggerEffect} />,
    },
    {
      label: "My History",
      content: <MyHistory triggerEffect={triggerEffect} />,
    },
  ];

  const handleTabClick = (label) => {
    setActiveTab(label);
  };

  const handleBetTabClick = (label) => {
    setBet(null);
    setBetActiveTab(label);
    if (label === "Sum") {
      setActiveBoxbet("");
      setActiveNumbers([]);
      setQty2(1);
      setMltValue(1);
    }
  };

  const handleInput = (e) => {
    let value = e.target.value;
    if (
      value !== "" &&
      !isNaN(value) &&
      Number(value) >= 1 &&
      Number(value) <= 9999
    ) {
       setMltValue(Number(Math.floor(value)));
    }
  };

  const handleMlt = (type) => {
    setMltValue((prevMltValue) => {
      let newValue = type ? prevMltValue + 1 : prevMltValue - 1;
      if (newValue < 1) newValue = 1;
      if (newValue > 9999) newValue = 9999;
      return newValue;
    });
  };

  const handleMlt2 = (value) => {
    setMltValue((prevMltValue) => {
      let newValue = value;
      return newValue;
    });
  };

  const handleBetAmount = (balance, mltValue, qty2) => {
    const totalBet = balance * mltValue * qty2;
    setBetAmount(totalBet);
  };

  const handleBalance = (value) => {
    setBalance(value);
  };

    const [toast, setToast] = useState({
      isVisible: false,
      message: "",
    });
    const showToast = (message) => {
      setToast({ isVisible: true, message });
      setTimeout(() => {
        setToast({ isVisible: false, message: "" });
      }, 3000); // Hide after 3 seconds
    };
  const confirmBet = async () => {
    clearTimeout(removeModel);
    if (!agree && true) {
      $("#smallPopup").addClass("show");
      $("#smallPopup").text("Please check I agree to the pre-sale rules");
      removeModel = setTimeout(
        () => {
          $("#smallPopup").removeClass("show");
        },
        2000,
        () => {
          $("#smallPopup").text("");
        }
      );
      return;
    }

    if (xmoney < betAmount) {
      $("#smallPopup").addClass("show");
      $("#smallPopup").html(
        `<span style="font-size:40px;">!</span><span>Error: 142 </br>Insufficient balance</span>`
      );
      removeModel = setTimeout(
        () => {
          $("#smallPopup").removeClass("show");
        },
        2000,
        () => {
          $("#smallPopup").text("");
        }
      );
      return;
    }

    const response = await betD5(
      10,
      selectBet,
      selectTab,
      betAmount,
    );

    if (response.status) {
      refreshUserData();
      setTriggerEffect((prevState) => !prevState);
      // $("#smallPopup").addClass("show");
      // $("#smallPopup").text(response.data.message);
      showToast("Bet succeed");
      toggleOffCanvas(false, null, null);
      removeModel = setTimeout(
        () => {
          $("#smallPopup").removeClass("show");
        },
        2000,
        () => {
          $("#smallPopup").text("");
        }
      );
    }
  };

  const handleAgree = () => {
    setAgree(!agree);
  };

 
  const soundEnabled = localStorage.getItem("soundEnabled") === "true";
  useEffect(() => {
    socket.current = io.connect(webhost);
    const eventName = `timeUpdate_10`;
    socket.current.on(eventName, (data) => {
      if (!data) {
        return "not event";
      }

      const { minute, secondtime1, secondtime2 } = data;
      setMinutetime2(minute);
      setSecondtime1(secondtime1);
      setSecondtime2(secondtime2);

      // Handle open time logic based on received data
      if (minute === 0 && secondtime1 === 0 && secondtime2 <= 5 && secondtime2 >= 1) {
        if (soundEnabled) low_audio.play();;
        $(".bet-section-model").addClass("show");
        $(".bet-section-model").removeClass("hide");
        setIsOpen(false);
        $(".overlay").addClass("d-none");
        $(".container").css("overflow", "");
      } else {
        $(".bet-section-model").addClass("hide");
        $(".bet-section-model").removeClass("show");
      }

      if (minute === 0 && secondtime1 === 5 && secondtime2 === 9) {
        if (soundEnabled) high_audio.play();

      }
    });
    return () => {
      socket.current.off(eventName);
      socket.current.disconnect(); // Disconnect socket when unmounting
    };
  }, [soundEnabled])

  const bet_tabs = [
    {
      label: "A",
    },
    {
      label: "B",
    },
    {
      label: "C",
    },
    {
      label: "D",
    },
    {
      label: "E",
    },
    {
      label: "Sum",
    },
  ];

  const handleActiveNumbers = (n) => {
    setActiveBoxbet("");
    setActiveNumbers((prevActiveNumbers) => {
      if (prevActiveNumbers.includes(n)) {
        return prevActiveNumbers.filter((number) => number !== n);
      } else {
        return [...prevActiveNumbers, n];
      }
    });
    setClick(!click);
  };

  const handleActiveBoxbet = (bet) => {
    setActiveNumbers([]);
    if (bet === activeBoxbet) {
      setActiveBoxbet("");
    } else {
      setActiveBoxbet(bet);
    }
    setClick(!click);
  };
  const checkBet = () => {
    return activeNumbers.length > 0 || activeBoxbet !== "";
  };

  const nums = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

  useEffect(() => {
    toggleOffCanvas(checkBet());
    const qty = activeNumbers.length + (activeBoxbet !== "" ? 1 : 0);
    setQty2(qty);
  }, [click]);

  useEffect(() => {
    setMoney(xmoney);
    fetchWingoList(1,10);
  }, [triggerEffect]);

  useEffect(() => {
    handleBetAmount(balance, mltValue, qty2);
  }, [balance, mltValue, qty2]);


  useEffect(() => {
    socket.on("data-server-5d", async (data) => {

      const response = await get5dBetHistory(1, 10, 10);
      // fetchUserWinStatus();
      fetchWingoList(1, 10);
  
      if (data?.data[1]?.period ==response?.data?.data?.gameslist[0]?.stage) {
        const status = response.data.data.gameslist[0].status;
        const datas = {
          status: status,
          type: "10Min",
          period_id: response.data.data.gameslist[0].stage,
          amount: response.data.data.gameslist[0].money,
          win_amount: response.data.data.gameslist[0].get,
          game: "5D",
          color: response.data.data.gameslist[0].bet,
          size: response.data.data.gameslist[0].bet,
          number: response.data.data.gameslist[0].result,
        };
        showResult(datas);
        setIsOpen(false);
        $(".overlay").addClass("d-none");
        $(".container").css("overflow", "");
        setTriggerEffect((prevState) => !prevState);
        refreshUserData();
        fetchWingoList(1, 10);
        setLoaded(true);
      }
    });
    return () => {
      socket.off('data-server-5d');
    };

  }, []);




  return (
    <>
      <Toast2
        message={toast.message}
        isVisible={toast.isVisible}
        onClose={() => setToast({ isVisible: false, message: "" })}
      />
      <div className="lottery-result-container">
        <div className="result1">
          <span>Lottery</span>
          <span>results</span>
        </div>
        <div className="result2">
          <div className="num-container">
            {numbers.map((n, i) => (
              <div className="num" key={i}>
                {n}
              </div>
            ))}
            <div className="">=</div>
            <div className="num " style={{ color: "#fff",background:"#00ae50" }}>
              {numbers.reduce((a, b) => Number(a) + Number(b), 0)}
            </div>
          </div>
          <div className="letter-container">
            <div className="letter">A</div>
            <div className="letter">B</div>
            <div className="letter">C</div>
            <div className="letter">D</div>
            <div className="letter">E</div>
            <div style={{ opacity: "0" }}>=</div>
            <div className="letter"></div>
          </div>
        </div>
      </div>
      <div className="ludo-meter">
        <div
          className="d-flex align-center justify-between"
          style={{ padding: "5px" }}
        >
          <div
            className="d-flex align-center justify-center"
            style={{ gap: "10px" }}
          >
            <span className="period-name">Period</span>
            <div
              className="d-flex align-center justify-center htp"
              onClick={() => {
                $("#howtoPlayModelK3").removeClass("hide");
                $("#howtoPlayModelK3").addClass("show");
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17px"
                height="17px"
                viewBox="0 0 24 24"
              >
                <path
                  fill="#6485ff"
                  d="M14.727 6.727H14V0H4.91c-.905 0-1.637.732-1.637 1.636v20.728c0 .904.732 1.636 1.636 1.636h14.182c.904 0 1.636-.732 1.636-1.636V6.727zm-.545 10.455H7.09v-1.364h7.09v1.364zm2.727-3.273H7.091v-1.364h9.818zm0-3.273H7.091V9.273h9.818zM14.727 6h6l-6-6z"
                />
              </svg>
              <span>How to play</span>
            </div>
          </div>
          <span className="tr">Time Remaining</span>
        </div>
        <div
          className="d-flex align-center justify-between"
          style={{ padding: "5px" }}
        >
          <span className="preiod-id">{Number(nextPreiod) + 1}</span>
          <div className="k3-counter-container">
            <span className="counter-num">{0}</span>
            <span className="counter-num">{minutetime2}</span>
            <span className="counter-num x">:</span>
            <span className="counter-num">{secondtime1}</span>
            <span className="counter-num">{secondtime2}</span>
          </div>
        </div>
        <div className="d-flex align-center justify-between pt-2">
          <div className="meter" onClick={randomize}>
            <div className="d5-meter-inner">
              {numbers.map((num, index) => (
                <div
                  className={`num ${index === 0 ? "active" : ""}`}
                  key={index}
                >
                  <div
                    className={`meter-num-container ${animating ? "roll" : ""}`}
                    style={{ animationDelay: `${index * 0.2}s` }}
                  >
                    <div className="meter-num">0</div>
                    <div className="meter-num">{numbers[index]}</div>
                    <div className="meter-num">2</div>
                    <div className="meter-num">3</div>
                    <div className="meter-num">4</div>
                    <div className="meter-num">5</div>
                    <div className="meter-num">6</div>
                    <div className="meter-num">7</div>
                    <div className="meter-num">8</div>
                    <div className="meter-num">9</div>
                    <div className="meter-num">0</div>
                    <div className="meter-num">1</div>
                    <div className="meter-num">2</div>
                    <div className="meter-num">3</div>
                    <div className="meter-num">4</div>
                    <div className="meter-num">5</div>
                    <div className="meter-num">6</div>
                    <div className="meter-num">7</div>
                    <div className="meter-num">8</div>
                    <div className="meter-num">{numbers[index]}</div>
                    <div className="meter-num">0</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="bet-section" style={{ padding: "0px" }}>
        <div
          className="bet-section-main"
          style={{ borderRadius: "0px 0px 10px 10px" }}
        >
          <div
            className="d-flex align-center justify-between"
            style={{ flexDirection: "column" }}
          >
            <div className="bet-tabs">
              {bet_tabs.map((tab, index) => (
                <div
                  key={tab.label}
                  className={`d5-bet-tab ${
                    betActiveTab === tab.label ? "active" : ""
                  }`}
                  onClick={() => handleBetTabClick(tab.label)}
                >
                  {tab.label}
                </div>
              ))}
            </div>
            <div className="d5-bet-tab-content" style={{ width: "100%" }}>
              {betActiveTab === "Sum" ? (
                <div className="d5-tab-bet">
                  <div className="d-flex align-center justify-between">
                    <div
                      className={`d5-box-bet ${
                        activeBoxbet === "big" ? "active" : ""
                      }`}
                      onClick={() => handleActiveBoxbet("big")}
                    >
                      <span>Big</span>
                      <span>1.98</span>
                    </div>
                    <div
                      className={`d5-box-bet ${
                        activeBoxbet === "small" ? "active" : ""
                      }`}
                      onClick={() => handleActiveBoxbet("small")}
                    >
                      <span>Small</span>
                      <span>1.98</span>
                    </div>
                    <div
                      className={`d5-box-bet ${
                        activeBoxbet === "odd" ? "active" : ""
                      }`}
                      onClick={() => handleActiveBoxbet("odd")}
                    >
                      <span>Odd</span>
                      <span>1.98</span>
                    </div>
                    <div
                      className={`d5-box-bet ${
                        activeBoxbet === "even" ? "active" : ""
                      }`}
                      onClick={() => handleActiveBoxbet("even")}
                    >
                      <span>Even</span>
                      <span>1.98</span>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="d5-tab-bet">
                  <div className="d-flex align-center justify-between">
                    <div
                      className={`d5-box-bet ${
                        activeBoxbet === "big" ? "active" : ""
                      }`}
                      onClick={() => handleActiveBoxbet("big")}
                    >
                      <span>Big</span>
                      <span>1.98</span>
                    </div>
                    <div
                      className={`d5-box-bet ${
                        activeBoxbet === "small" ? "active" : ""
                      }`}
                      onClick={() => handleActiveBoxbet("small")}
                    >
                      <span>Small</span>
                      <span>1.98</span>
                    </div>
                    <div
                      className={`d5-box-bet ${
                        activeBoxbet === "odd" ? "active" : ""
                      }`}
                      onClick={() => handleActiveBoxbet("odd")}
                    >
                      <span>Odd</span>
                      <span>1.98</span>
                    </div>
                    <div
                      className={`d5-box-bet ${
                        activeBoxbet === "even" ? "active" : ""
                      }`}
                      onClick={() => handleActiveBoxbet("even")}
                    >
                      <span>Even</span>
                      <span>1.98</span>
                    </div>
                  </div>
                  <div
                    className="d-flex align-center justify-between"
                    style={{ padding: "20px 10px", flexWrap: "wrap" }}
                  >
                    {nums.map((n, i) => (
                      <div className="d5-num-container" key={i}>
                        <div
                          className={`d5-bet-circle ${
                            activeNumbers.includes(n) ? "active" : ""
                          }`}
                          onClick={() => handleActiveNumbers(n)}
                        >
                          {n}
                        </div>
                        <span style={{ fontSize: ".85rem", color: "#768096" }}>
                          9
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="bet-section-model" style={{ marginTop: "0px" }}>
        <div className="counter1">{secondtime1}</div>
        <div className="counter2">{secondtime2}</div>
        </div>
      </div>
      <div className="history-tabs">
        {tabs.map((tab, index) => (
          <div
            key={tab.label}
            className={`history-tab ${activeTab === tab.label ? "active" : ""}`}
            onClick={() => handleTabClick(tab.label)}
          >
            {tab.label}
          </div>
        ))}
      </div>
      <div className="history-tab-content">
        {tabs.find((tab) => tab.label === activeTab)?.content}
      </div>
      <div className={`off-canvas ${isOpen ? "open" : ""}`}>
        <div className="off-canvas-container">
          <div className="off-canvas-content">
            <div className="k3-model-content1">
              <div className="k3-content-1">
                <div className="bet-tabs">
                  {bet_tabs.map((tab, index) => (
                    <div
                      key={tab.label}
                      className={`d5-bet-tab ${
                        betActiveTab === tab.label ? "active" : ""
                      }`}
                      onClick={() => handleBetTabClick(tab.label)}
                    >
                      {tab.label}
                    </div>
                  ))}
                </div>
                <div className="d5-bet-tab-content" style={{ width: "100%" }}>
                  {betActiveTab === "Sum" ? (
                    <div className="d5-tab-bet">
                      <div className="d-flex align-center justify-between">
                        <div
                          className={`d5-box-bet ${
                            activeBoxbet === "big" ? "active" : ""
                          }`}
                          onClick={() => handleActiveBoxbet("big")}
                        >
                          <span>Big</span>
                          <span>1.98</span>
                        </div>
                        <div
                          className={`d5-box-bet ${
                            activeBoxbet === "small" ? "active" : ""
                          }`}
                          onClick={() => handleActiveBoxbet("small")}
                        >
                          <span>Small</span>
                          <span>1.98</span>
                        </div>
                        <div
                          className={`d5-box-bet ${
                            activeBoxbet === "odd" ? "active" : ""
                          }`}
                          onClick={() => handleActiveBoxbet("odd")}
                        >
                          <span>Odd</span>
                          <span>1.98</span>
                        </div>
                        <div
                          className={`d5-box-bet ${
                            activeBoxbet === "even" ? "active" : ""
                          }`}
                          onClick={() => handleActiveBoxbet("even")}
                        >
                          <span>Even</span>
                          <span>1.98</span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="d5-tab-bet">
                      <div className="d-flex align-center justify-between">
                        <div
                          className={`d5-box-bet ${
                            activeBoxbet === "big" ? "active" : ""
                          }`}
                          onClick={() => handleActiveBoxbet("big")}
                        >
                          <span>Big</span>
                          <span>1.98</span>
                        </div>
                        <div
                          className={`d5-box-bet ${
                            activeBoxbet === "small" ? "active" : ""
                          }`}
                          onClick={() => handleActiveBoxbet("small")}
                        >
                          <span>Small</span>
                          <span>1.98</span>
                        </div>
                        <div
                          className={`d5-box-bet ${
                            activeBoxbet === "odd" ? "active" : ""
                          }`}
                          onClick={() => handleActiveBoxbet("odd")}
                        >
                          <span>Odd</span>
                          <span>1.98</span>
                        </div>
                        <div
                          className={`d5-box-bet ${
                            activeBoxbet === "even" ? "active" : ""
                          }`}
                          onClick={() => handleActiveBoxbet("even")}
                        >
                          <span>Even</span>
                          <span>1.98</span>
                        </div>
                      </div>
                      <div
                        className="d-flex align-center justify-between"
                        style={{ padding: "20px 10px", flexWrap: "wrap" }}
                      >
                        {nums.map((n, i) => (
                          <div className="d5-num-container" key={i}>
                            <div
                              className={`d5-bet-circle ${
                                activeNumbers.includes(n) ? "active" : ""
                              }`}
                              onClick={() => handleActiveNumbers(n)}
                            >
                              {n}
                            </div>
                            <span
                              style={{ fontSize: ".85rem", color: "#768096" }}
                            >
                              9
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="content2">
              <div className="content2-balance">
                <span>Balance</span>
                <div className="d-flex">
                  {[1, 10, 100, 1000].map((val) => (
                    <div
                      key={val}
                      className={`x-box ${
                        balance === val ? "active " + bg : ""
                      }`}
                      onClick={() => handleBalance(val)}
                    >
                      {val}
                    </div>
                  ))}
                </div>
              </div>
              <div className="content2-qty">
                <span>Quantity</span>
                <div className="d-flex">
                  <div
                    className={`x-box btn ${bg}`}
                    onClick={() => handleMlt(false)}
                  >
                    -
                  </div>
                  <div className="x-box mlt">
                    <input
                      type="number"
                      className="mlt-input"
                      value={mltValue}
                      readOnly={true}
                      onChange={handleInput}
                      min="1"
                      max="9999"
                    />
                  </div>
                  <div
                    className={`x-box btn ${bg}`}
                    onClick={() => handleMlt(true)}
                  >
                    +
                  </div>
                </div>
              </div>
              <div className="content2-mlt">
                <div className="d-flex">
                  {[1, 5, 10, 20, 50, 100].map((multiplier) => (
                    <div
                      key={multiplier}
                      className={`x-box ${
                        mltValue === multiplier ? "active " + bg : ""
                      }`}
                      onClick={() => handleMlt2(multiplier)}
                    >
                      X{multiplier}
                    </div>
                  ))}
                </div>
              </div>
              <div className="content2-policy">
                <div className="d-flex align-center" style={{ gap: "10px" }}>
                  <div
                    className="d-flex align-center"
                    style={{ gap: "10px" }}
                    onClick={handleAgree}
                  >
                    <img
                      src={agree ? checkImg : checkCircle}
                      alt=""
                      className="check-img"
                    />
                    <span>I agree</span>
                  </div>
                  <span
                    className="policy-btn"
                    onClick={() => {
                      $("#agreeModel").removeClass("hide");
                      $("#agreeModel").addClass("show");
                    }}
                  >
                    《Pre-sale rules》
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="off-canvas-footer">
            <div
              className="cancel"
              onClick={() => toggleOffCanvas(false, null, null)}
            >
              Cancel
            </div>
            <div className={`amt ${bg}`} onClick={confirmBet}>
              Total amount {formatMoney(betAmount)}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default D51;

// History Tab Component
const GameHistory = ({ triggerEffect }) => {
  const [wingoList, setWingoList] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [pageNoTo, setPageNoTo] = useState(10);
  const [pageCount, setPageCount] = useState(0);

  const fetchWingoList = async (page,pageto) => {
    try {
      const response = await getD5List(10,page,pageto);
      if (response.data.status) {
        setWingoList(response?.data?.data?.gameslist);
        setPageCount(response.data.page);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const nextPage = () => {
    if (pageCount === pageNo) {
      return;
    }
    setPageNo((prevPageNo) => prevPageNo + 10);
    setPageNoTo((prevPageNo) => prevPageNo + 10);
    fetchWingoList(pageNo + 10, pageNoTo + 10);
  };

  const prevPage = () => {
    if (pageNo <= 0) {
      return;
    }
    setPageNo((prevPageNo) => prevPageNo - 10);
    setPageNoTo((prevPageNo) => prevPageNo - 10);
    fetchWingoList(pageNo - 10, pageNoTo - 10);
  };

  useEffect(() => {
    fetchWingoList(1, 10);
    socket.on("data-server-5d", async (data) => {
      fetchWingoList(1, 10);
    });
    return () => {
      socket.off('data-server-5d');
    };
  }, [triggerEffect]);
    return (
    <>
      <div className="game-hsitory">
        <div className="game-history-header">
          <span>Period</span>
          <span>Results</span>
          <span>Total</span>
        </div>
        <div className="game-history-content">
        {wingoList.map((item, index) => (
            <div className="history-item" key={index}>
              <span>{item.period}</span>
              <div style={{ display: "flex", gap: "5px" }}>
                {item.result.split("").map((item, index) => (
                  <div className="border-box" key={index}>
                    {item}
                  </div>
                ))}
              </div>
              <span>
              {(String(item.result).split('').reduce((acc, digit) => acc + parseInt(digit), 0))}
              </span>
            </div>
          ))}
        </div>
      </div>
      <div className="game-history-footer">
        <div
          className={`prev ${pageNo > 0 ? "active" : ""}`}
          onClick={() => prevPage()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25px"
            height="25px"
            viewBox="0 0 24 24"
          >
            <path
              fill="#768096"
              d="M13.83 19a1 1 0 0 1-.78-.37l-4.83-6a1 1 0 0 1 0-1.27l5-6a1 1 0 0 1 1.54 1.28L10.29 12l4.32 5.36a1 1 0 0 1-.78 1.64"
            />
          </svg>
        </div>
        <div className="pages">
          <span>{pageNoTo/10}</span>/<span>{pageCount}</span>
        </div>
        <div
          className={`next ${pageNo < pageCount ? "active" : ""}`}
          onClick={() => nextPage()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25px"
            height="25px"
            viewBox="0 0 24 24"
          >
            <path
              fill="#fff"
              d="M10 19a1 1 0 0 1-.64-.23a1 1 0 0 1-.13-1.41L13.71 12L9.39 6.63a1 1 0 0 1 .15-1.41a1 1 0 0 1 1.46.15l4.83 6a1 1 0 0 1 0 1.27l-5 6A1 1 0 0 1 10 19"
            />
          </svg>
        </div>
      </div>
    </>
  );
};

const Chart = ({ triggerEffect }) => {
  const canvasRef = useRef("null");
  const [wingoList, setWingoList] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [pageNoTo, setPageNoTo] = useState(10);
  const [pageCount, setPageCount] = useState(0);

  const fetchWingoList = async (page,pageto) => {
    try {
      const response = await getD5List(10, page, pageto);
      if (response.data.status) {
        setWingoList(response.data.data.gameslist);
        setPageCount(response.data.page);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const nextPage = () => {
    if (pageCount === pageNo) {
      return;
    }
    setPageNo((prevPageNo) => prevPageNo + 10);
    setPageNoTo((prevPageNo) => prevPageNo + 10);
    fetchWingoList(pageNo + 10, pageNoTo + 10);
  };

  const prevPage = () => {
    if (pageNo <= 0) {
      return;
    }
    setPageNo((prevPageNo) => prevPageNo - 10);
    setPageNoTo((prevPageNo) => prevPageNo - 10);
    fetchWingoList(pageNo - 10, pageNoTo - 10);
  };

  useEffect(() => {
    fetchWingoList(1,10);
  }, [triggerEffect]);

  const getSize = (numResult) => {
    if (numResult > "4") {
      return { label: "H", class: "bg-big" };
    } else {
      return { label: "L", class: "bg-small" };
    }
  };
  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    // Clear the canvas
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    const connectElements = document.querySelectorAll(".connect");
    if (connectElements.length > 1) {
      ctx.beginPath();
      ctx.strokeStyle = "#f95959";
      ctx.lineWidth = 1;

      connectElements.forEach((elem, index) => {
        const rect = elem.getBoundingClientRect();
        const canvasRect = canvas.getBoundingClientRect();
        const x = rect.left + rect.width / 2 - canvasRect.left;
        const y = rect.top + rect.height / 2 - canvasRect.top;

        if (index === 0) {
          ctx.moveTo(x, y);
        } else {
          ctx.lineTo(x, y);
        }
      });

      ctx.stroke();
    }
  }, [wingoList]);

  return (
    <>
    <div className="chart-history">
    <div className="chart-item-first">
          <div className="chart-item-first-content">
            Statistic (last 100 Periods)
          </div>
          <div className="chart-item-first-content mt-1">
            <span>Winning number</span>
            <span>
              <div className="nums">
                <div>0</div>
                <div>1</div>
                <div>2</div>
                <div>3</div>
                <div>4</div>
                <div>5</div>
                <div>6</div>
                <div>7</div>
                <div>8</div>
                <div>9</div>
              </div>
            </span>
          </div>
          <div className="chart-item-first-content mt-1">
            <span>Missing</span>
            <span>
              <div className="nums x">
                <div>8</div>
                <div>1</div>
                <div>12</div>
                <div>3</div>
                <div>41</div>
                <div>5</div>
                <div>16</div>
                <div>7</div>
                <div>81</div>
                <div>91</div>
              </div>
            </span>
          </div>
          <div className="chart-item-first-content mt-1">
            <span>Avarage missing</span>
            <span>
              <div className="nums x">
                <div>11</div>
                <div>1</div>
                <div>12</div>
                <div>3</div>
                <div>71</div>
                <div>5</div>
                <div>36</div>
                <div>74</div>
                <div>61</div>
                <div>39</div>
              </div>
            </span>
          </div>
          <div className="chart-item-first-content mt-1">
            <span>Frequency</span>
            <span>
              <div className="nums x">
                <div>3</div>
                <div>7</div>
                <div>4</div>
                <div>3</div>
                <div>21</div>
                <div>45</div>
                <div>76</div>
                <div>7</div>
                <div>23</div>
                <div>56</div>
              </div>
            </span>
          </div>
          <div className="chart-item-first-content mt-1">
            <span>Max consecutive</span>
            <span>
              <div className="nums x">
                <div>2</div>
                <div>1</div>
                <div>12</div>
                <div>3</div>
                <div>42</div>
                <div>5</div>
                <div>11</div>
                <div>7</div>
                <div>21</div>
                <div>23</div>
              </div>
            </span>
          </div>
        </div>
      <div className="chart-history-header">
        <span>Period</span>
        <span>Number</span>
      </div>
      <div className="chart-history-content">
        <canvas
          ref={canvasRef}
          width={800}
          height={500}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 1,
          }}
        ></canvas>

        {wingoList.map((item, index) => (
          <div className="chart-item" key={index}>
            <span>{item.period}</span>
            <span>

              <div className="nums">
                <div
                  className={
                    item?.result?.split("")[0] === 0 ? "c0 connect bg-rv" : ""
                  }
                >
                  0
                </div>
                <div className={item?.result?.split("")[0] == 1 ? "c1 connect" : ""}>
                  1
                </div>
                <div className={item?.result?.split("")[0] == 2 ? "c2 connect" : ""}>
                  2
                </div>
                <div className={item?.result?.split("")[0] == 3 ? "c3 connect" : ""}>
                  3
                </div>
                <div className={item?.result?.split("")[0] == 4 ? "c4 connect" : ""}>
                  4
                </div>
                <div
                  className={
                    item?.result?.split("")[0] == 5 ? "c5 connect bg-gv" : ""
                  }
                >
                  5
                </div>
                <div className={item?.result?.split("")[0] == 6 ? "c6 connect" : ""}>
                  6
                </div>
                <div className={item?.result?.split("")[0] == 7 ? "c7 connect" : ""}>
                  7
                </div>
                <div className={item?.result?.split("")[0] == 8 ? "c8 connect" : ""}>
                  8
                </div>
                <div className={item?.result?.split("")[0] == 9 ? "c9 connect" : ""}>
                  9
                </div>
              </div>
              <div
                style={{ fontWeight: "400", marginLeft: "10px" }}
                className={`size-text-box ${getSize(item.result?.split("")[0]).class}`}
              >
                {getSize(item.result?.split("")[0]).label}
              </div>
            </span>
          </div>
        ))}
      </div>
    </div>
    <div className="chart-history-footer">
      <div
        className={`prev ${pageNo > 0 ? "active" : ""}`}
        onClick={prevPage}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25px"
          height="25px"
          viewBox="0 0 24 24"
        >
          <path
            fill="#768096"
            d="M13.83 19a1 1 0 0 1-.78-.37l-4.83-6a1 1 0 0 1 0-1.27l5-6a1 1 0 0 1 1.54 1.28L10.29 12l4.32 5.36a1 1 0 0 1-.78 1.64"
          />
        </svg>
      </div>
      <div className="pages">
        <span>{pageNoTo / 10}</span>/<span>{pageCount}</span>
      </div>
      <div
        className={`next ${pageNo < pageCount ? "active" : ""}`}
        onClick={nextPage}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25px"
          height="25px"
          viewBox="0 0 24 24"
        >
          <path
            fill="#fff"
            d="M10 19a1 1 0 0 1-.64-.23a1 1 0 0 1-.13-1.41L13.71 12L9.39 6.63a1 1 0 0 1 .15-1.41a1 1 0 0 1 1.46.15l4.83 6a1 1 0 0 1 0 1.27l-5 6A1 1 0 0 1 10 19"
          />
        </svg>
      </div>
    </div>
  </>
  );
};

const MyHistory = ({ triggerEffect }) => {
  const [history, setHistory] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [pageNoTo, setPageNoTo] = useState(10);
  const [pageCount, setPageCount] = useState(0);
   const [details, setDetails] = useState(null)
  const fetchMyHistory = async (page, pageto) => {
    try {
      const response = await get5dBetHistory(page, pageto, 10);
      if (response.data.status) {
        setHistory(response.data.data.gameslist);
        setPageCount(response.data.page);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const nextPage = () => {
    if (pageCount === pageNo) {
      return;
    }
    setPageNo((prevPageNo) => prevPageNo + 10);
    setPageNoTo((prevPageNo) => prevPageNo + 10);
    fetchMyHistory(pageNo + 10, pageNoTo + 10);
  };

  const prevPage = () => {
    if (pageNo <= 0) {
      return;
    }
    setPageNo((prevPageNo) => prevPageNo - 10);
    setPageNoTo((prevPageNo) => prevPageNo - 10);
    fetchMyHistory(pageNo - 10, pageNoTo - 10);
  };
  const [expandedIndex, setExpandedIndex] = useState(null);

  const handleToggle = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };
  useEffect(() => {
    fetchMyHistory(1, 10);
  }, [triggerEffect]);
  
  const handleDetail = (i) => {
    if (details === i) {
      return setDetails(null)
    }
    setDetails(i)
  }
   const [toast, setToast] = useState({
      isVisible: false,
      message: "",
    });
  const showToast = (message) => {
    setToast({ isVisible: true, message });
    setTimeout(() => {
      setToast({ isVisible: false, message: "" });
    }, 3000); // Hide after 3 seconds
  };
  const copyToClipboard = (number) => {
    navigator.clipboard.writeText(String(number))
      .then(() => {
        showToast(
          `<svg width="25px" height="25px" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="iconify iconify--emojione-monotone" preserveAspectRatio="xMidYMid meet" fill="#fff" stroke="#fff"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M56 2L18.8 42.909L8 34.729H2L18.8 62L62 2z" fill="#fff"></path></g></svg>Copy successfully`
        );
      })
      .catch(err => {
        console.error('Failed to copy the text: ', err);
      });
  };
  const mappings = {
    s: "small",
    l: "odd",
    c: "even",
    b: "big"
};
return (
  <>

<Toast
      message={toast.message}
      isVisible={toast.isVisible}
      onClose={() => setToast({ isVisible: false, message: "" })}
    />
      <div className="my-history">
        <div className="my-history-details">
          <div className="details-btn">
            Details
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20px"
              height="20px"
              viewBox="0 0 512 512"
            >
              <path
                fill="none"
                stroke="#6485ff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="32"
                d="M262.62 336L342 256l-79.38-80m68.35 80H170"
              />
              <path
                fill="none"
                stroke="#6485ff"
                strokeMiterlimit="10"
                strokeWidth="32"
                d="M256 448c106 0 192-86 192-192S362 64 256 64S64 150 64 256s86 192 192 192Z"
              />
            </svg>
          </div>
        </div>
        <div className="my-history-content">
        {history.length > 0 ? (
           history.map((item, index) => (
                        <div
                          className="my-history-item"
                          key={index}
          
                        >
                          <div
                            className="d-flex align-center justify-between"
                            style={{ width: "100%" }}
                            onClick={() => handleDetail(index)}
                          >
                            <div className="d-flex align-center" style={{ gap: "10px" }}>
          
                              <div className={`flex justify-center items-center h-10 w-10 rounded-md mr-2 
                                        ${(item.bet).split(',')[0] == "c" ? "bgs-green" : (item.bet).split(',')[0] == "l" ? "bgs-red-200" : (item.bet).split(',')[0] == "b" ? "color-yellow-bg-200" : "bgs-blue-500"}
                                         `}>
                                {(item.bet).split(',')[0] == "c" ? "Even" : (item.bet).split(',')[0] == "l" ? "Odd" : (item.bet).split(',')[0] == "b" ? "Big" : (item.bet).split(',')[0] == "s" ? "Small" : (item.bet).split(',')[0]}
                              </div>
          
                              <div
                                className="d-flex flex-col"
                                style={{ alignItems: "start", gap: "10px" }}
                              >
                                <span>{item.stage}</span>
                                <span style={{ fontSize: ".8rem", color: "#768096" }}>
                                  {item.time}
                                </span>
                              </div>
                            </div>
                            <div className="win-status">
                              {item.status === 1 ? (
                                <>
                                  <div
                                    className="status-box"
                                    style={{ color: "#00ae50", borderColor: "#00ae50" }}
                                  >
                                    {"Succeed"}
                                  </div>
                                  <div
                                    className="result-amt"
                                    style={{ color: "#00ae50" }}
                                  >
                                    +{formatMoney(item.get)}
                                  </div>
                                </>
                              ) : (
                                <>
                                  {item.status === 2 ? (
                                    <>
                                      <div
                                        className="status-box"
                                        style={{
                                          color: "rgb(249, 89, 89)",
                                          borderColor: "rgb(249, 89, 89)",
                                        }}
                                      >
                                        {"Failed"}
                                      </div>
                                      <div
                                        className="result-amt"
                                        style={{ color: "rgb(249, 89, 89)" }}
                                      >
                                        -{formatMoney(item.price)}
                                      </div>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                          <div className={`mt-3 history-details ${details === index ? "active mb-5" : ""}`}>
                            <h2 className='heading-h2 gray-50 text-lg mb-1'>Details</h2>
                            <div className='flex items-center justify-between bg-color-l p-1 mb-1 rounded-md' style={{ fontSize: "15px" }}>
                              <span className=' gray-100 '>Order number</span>
                              <span className=' gray-100 flex item-center'>{item.id_product}<PiCopySimpleBold className='mt-[3px]' onClick={() => copyToClipboard(String(item.id_product))} /></span>
                            </div>
                            <div className='flex items-center justify-between bg-color-l p-1 mb-1 rounded-md'>
                              <span className=' gray-100 '>Period</span>
                              <span className=' gray-100 '>{item.stage}</span>
                            </div>
                            <div className='flex items-center justify-between bg-color-l p-1 mb-1  rounded-md'>
                              <span className=' gray-100 '>Purchase amount</span>
                              <span className=' gray-100 '>₹{item.money}</span>
                            </div>
                            <div className='flex items-center justify-between bg-color-l p-1 mb-1  rounded-md'>
                              <span className=' gray-100 '>Quantiy</span>
                              <span className=' gray-100 '>{item.amount}</span>
                            </div>
                            <div className='flex items-center justify-between bg-color-l p-1 mb-1  rounded-md'>
                              <span className='gray-100 '>Amount after tax</span>
                              <span className='color-red-200 '>₹{item.money}</span>
                            </div>
                            <div className='flex items-center justify-between bg-color-l p-1 mb-1  rounded-md'>
                              <span className='gray-100 '>Tax</span>
                              <span className='gray-100 '>₹{item.fee}</span>
                            </div>
                            <div className='flex items-center justify-between bg-color-l p-1 mb-1  rounded-md'>
                              <span className='gray-100 '>Result</span>
                              <span className='gray-100 flex justify-center items-center d5-results'> {item?.result?.split("").map((data, i) => (
                                <div className='text-sm gray-100 w-6 mr-1 h-6 flex justify-center items-center border-2 rounded-full  border-[--gray-400]'>{data}</div>
                              ))}</span>
                            </div>
                            <div className='flex items-center justify-between   bg-color-l p-1 mb-1  rounded-md'>
                              <p className='gray-100'>Select</p>
                              <span className=' gray-100 flex'>
                                {(item.bet) == "c" ? "Even" : (item.bet) == "l" ? "Odd" : (item.bet) == "b" ? "Big" : (item.bet) == "s" ? (item.bet).split(',').map(char => mappings[char]).join(' ') : item.bet}</span>
                            </div>
                            <div className='flex items-center justify-between bg-color-l p-1 mb-1  rounded-md'>
                              <span className=' gray-100 '>Status</span>
                              {item.status !== 0 && (
                                <span className={` color-red-200 ${item.status == 1 ? "color-green" : "color-red-200"}`}> {item.status === 1 ? "Succeed" : " Failed"}</span>
                              )}
                            </div>
                            <div className='flex items-center justify-between bg-color-l p-1 mb-1  rounded-md'>
                              <span className=' gray-100 '>Win/loss</span>
                              {item.status !== 0 && (
                                <span className={` ${item.status === 1 ? "color-green " : "color-red-200"}`}>
                                  {item.status === 1 ? ("+₹" + item.get) : ("-₹" + item.money)}
          
                                </span>
                              )}
                            </div>
                            <div className='flex items-center justify-between bg-color-l p-1 mb-1  rounded-md'>
                              <span className=' gray-100 '>Order time</span>
                              <span className=' gray-100 '>{item.time}</span>
                            </div>
          
                          </div>
                        </div>
                      ))
          ) : (
            <div className="empty">
              <img src={noData} alt="" className="noData" />
              <span>No Data</span>
            </div>
          )}
        </div>
      </div>
      {history.length > 0 ? (
        <div className="my-history-footer">
          <div
            className={`prev ${pageNo > 0 ? "active" : ""}`}
            onClick={prevPage}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25px"
              height="25px"
              viewBox="0 0 24 24"
            >
              <path
                fill="#768096"
                d="M13.83 19a1 1 0 0 1-.78-.37l-4.83-6a1 1 0 0 1 0-1.27l5-6a1 1 0 0 1 1.54 1.28L10.29 12l4.32 5.36a1 1 0 0 1-.78 1.64"
              />
            </svg>
          </div>
          <div className="pages">
            <span>{pageNoTo/10}</span>/
            <span>{pageCount > 0 ? pageCount : 1}</span>
          </div>
          <div
            className={`next ${pageNo < pageCount ? "active" : ""}`}
            onClick={nextPage}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25px"
              height="25px"
              viewBox="0 0 24 24"
            >
              <path
                fill="#fff"
                d="M10 19a1 1 0 0 1-.64-.23a1 1 0 0 1-.13-1.41L13.71 12L9.39 6.63a1 1 0 0 1 .15-1.41a1 1 0 0 1 1.46.15l4.83 6a1 1 0 0 1 0 1.27l-5 6A1 1 0 0 1 10 19"
              />
            </svg>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
